import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Container, SearchField } from "../ui-components";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
  RectangleGroupIcon,
} from "@heroicons/react/20/solid";

const products = [
  {
    name: "Analytics",
    description: "Get a better understanding where your traffic is coming from",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers with our engagement tool",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    description: "Your customers’ data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Integrations",
    description: "Your customers’ data will be safe and secure",
    href: "#",
    icon: SquaresPlusIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
  { name: "View all products", href: "#", icon: RectangleGroupIcon },
];

const people = [
  { id: 1, name: "Leslie Alexander", url: "#" },
  // More people...
];

export default function HeaderSearch() {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const filteredPeople =
    query === ""
      ? []
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Popover className="w-full">
      <PopoverButton className="w-full max-w-[300px]">
        <div className="relative w-full">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <input
            id={"head-search"}
            type={"text"}
            placeholder="What would you like to learn?"
            // onClick={() => setOpen(true)}
            className={
              "bg-input-background border-0 text-sm rounded-4xl w-full max-w-72 h-10 pl-10 text-input-text placeholder:text-input-placeholder"
            }
          />
        </div>
      </PopoverButton>

      <PopoverPanel
        transition
        className=" absolute -ml-4 sm:-ml-6 lg:-ml-8 sm:-mr-6 lg:-mr-8 -mr-4 inset-x-0 top-14 -z-10 bg-white shadow-lg  transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="mx-auto grid w-full grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
          {products.map((item) => (
            <div
              key={item.name}
              className="group relative rounded-lg p-6 text-sm/6 hover:bg-gray-50">
              <div className="flex size-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <item.icon
                  aria-hidden="true"
                  className="size-6 text-gray-600 group-hover:text-indigo-600"
                />
              </div>
              <a
                href={item.href}
                className="mt-6 block font-semibold text-gray-900">
                {item.name}
                <span className="absolute inset-0" />
              </a>
              <p className="mt-1 text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
        <div className="bg-gray-50">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="grid grid-cols-3 divide-x divide-gray-900/5 border-x border-gray-900/5">
              {callsToAction.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-900 hover:bg-gray-100">
                  <item.icon
                    aria-hidden="true"
                    className="size-5 flex-none text-gray-400"
                  />
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
