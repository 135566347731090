import React from "react";

const Icon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H16.175C16.4417 3 16.6958 3.05 16.9375 3.15C17.1792 3.25 17.3917 3.39167 17.575 3.575L20.425 6.425C20.6083 6.60833 20.75 6.82083 20.85 7.0625C20.95 7.30417 21 7.55833 21 7.825V11C21 11.2833 20.9042 11.5208 20.7125 11.7125C20.5208 11.9042 20.2833 12 20 12C19.7167 12 19.4792 11.9042 19.2875 11.7125C19.0958 11.5208 19 11.2833 19 11V7.825L16.175 5H5V19H10C10.2833 19 10.5208 19.0958 10.7125 19.2875C10.9042 19.4792 11 19.7167 11 20C11 20.2833 10.9042 20.5208 10.7125 20.7125C10.5208 20.9042 10.2833 21 10 21H5ZM13 22V20.35C13 20.2167 13.025 20.0875 13.075 19.9625C13.125 19.8375 13.2 19.725 13.3 19.625L18.525 14.425C18.675 14.275 18.8417 14.1667 19.025 14.1C19.2083 14.0333 19.3917 14 19.575 14C19.775 14 19.9667 14.0375 20.15 14.1125C20.3333 14.1875 20.5 14.3 20.65 14.45L21.575 15.375C21.7083 15.525 21.8125 15.6917 21.8875 15.875C21.9625 16.0583 22 16.2417 22 16.425C22 16.6083 21.9667 16.7958 21.9 16.9875C21.8333 17.1792 21.725 17.35 21.575 17.5L16.375 22.7C16.275 22.8 16.1625 22.875 16.0375 22.925C15.9125 22.975 15.7833 23 15.65 23H14C13.7167 23 13.4792 22.9042 13.2875 22.7125C13.0958 22.5208 13 22.2833 13 22ZM14.5 21.5H15.45L18.475 18.45L18.025 17.975L17.55 17.525L14.5 20.55V21.5ZM18.025 17.975L17.55 17.525L18.475 18.45L18.025 17.975ZM7 10H14C14.2833 10 14.5208 9.90417 14.7125 9.7125C14.9042 9.52083 15 9.28333 15 9V7C15 6.71667 14.9042 6.47917 14.7125 6.2875C14.5208 6.09583 14.2833 6 14 6H7C6.71667 6 6.47917 6.09583 6.2875 6.2875C6.09583 6.47917 6 6.71667 6 7V9C6 9.28333 6.09583 9.52083 6.2875 9.7125C6.47917 9.90417 6.71667 10 7 10ZM12 18H12.1L15 15.125V15C15 14.1667 14.7083 13.4583 14.125 12.875C13.5417 12.2917 12.8333 12 12 12C11.1667 12 10.4583 12.2917 9.875 12.875C9.29167 13.4583 9 14.1667 9 15C9 15.8333 9.29167 16.5417 9.875 17.125C10.4583 17.7083 11.1667 18 12 18Z" fill="#5F6368"/>
    </svg>

  );
};

export default Icon;
