import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, useLoaderData } from "react-router-dom";
import { Provider } from 'react-redux';
import store, { history } from './redux/store';
import logo from './logo.svg';
import './App.css';
import ThemeLayout from './layout/themeLayout';
import Home, { homeLoader } from './screens/home';
import AuthLayout from './layout/authLayout';
import Auth, { authLoader } from './screens/auth';
import Signin, { signinLoader } from './screens/auth/signin';
import Password, { passwordLoader } from './screens/auth/password';
import ForgotPassword, { forgotPasswordLoader } from './screens/auth/forgot';
import Verification, { verificationLoader } from './screens/auth/verification';
import OnBoardingLayout from './layout/onBoardingLayout';
import OnBoarding, { OnBoardingLoader } from './screens/onboarding';
import NewPassword, { newPasswordLoader } from './screens/auth/newPassword';
import FullScreenMessageLayout from './layout/fullScreenMessage';
import AuthFinal, { authFinalLoader } from './screens/fullscreen/authFinal';
import OnBoardingFinal, { onBoardingFinalLoader } from './screens/fullscreen/onBoardingFinal';
import AboutUs, { aboutLoader } from './screens/aboutus'
import Instructor, { instructorLoader } from './screens/instructor';
import FaqPage, { faqLoader } from './screens/faqs';
import Features, { featuresLoader } from './screens/features';
import DashboardLayout from './screens/dashboard';
import MyProfile, { myProfileLoader } from './screens/dashboard/profile';
import PoliciesAgreementsLayout from './screens/privacPoliciesAgreements';
import MyCourses, { myCoursesLoader } from './screens/dashboard/courses';
import PrivacyPolicy, { privacyPolicyLoader } from './screens/privacPoliciesAgreements/privacyPolicy';
import TermsAndConditions, { termsAndConditionsLoader } from './screens/privacPoliciesAgreements/termsConditions';
import InstructorAgreement, { instructorAgreementLoader } from './screens/privacPoliciesAgreements/instructorAgreement';
import VenueProviderAgreement, { venueProviderAgreementLoader } from './screens/privacPoliciesAgreements/venueProviderAgreement';
import PaymentProcessingAgreement, { paymentProcessingAgreementLoader } from './screens/privacPoliciesAgreements/paymentProcessingAgreement';
import Courses, { coursesLoader } from './screens/courses';
import NotificationPage, { notificationPageLoader } from './screens/notification';
import CourseDetail, { courseDetailLoader } from './screens/courseDetail';
import Category, { categoryLoader } from './screens/category';
import ContactUs, { contactLoader } from './screens/contactus';
import Forum, { forumLoader } from './screens/forum';
import Chat, { chatLoader } from './screens/chat';
import Setting, { settingLoader } from './screens/dashboard/setting';

// import AuthLayout from './layout/authLayout';
// import Auth, { authLoader } from './screens/auth';
// import Signin, { signinLoader } from './screens/auth/sidescreens/signin';
// import Password, { passwordLoader } from './screens/auth/sidescreens/password';
// import ForgotPassword, { forgotPasswordLoader } from './screens/auth/sidescreens/forgot';
// import Verification, { verificationLoader } from './screens/auth/sidescreens/verification';
// trying

// const Protected = () => {
//   const token = localStorage.getItem("token");

//   return token ? <Outlet /> : <Navigate to="/signin" />;
// };

let router = createBrowserRouter([
  {
    path: "/",
    Component: ThemeLayout,
    children: [
      {
        index: true,
        loader: homeLoader,
        Component: Home,
      },
      {
        path:'aboutus',
        loader: aboutLoader,
        Component: AboutUs,
      },
      {
        path:'instructor',
        loader: instructorLoader,
        Component: Instructor,
      },
      {
        path:'faq',
        loader: faqLoader,
        Component: FaqPage,
      },
      {
        path:'features',
        loader: featuresLoader,
        Component: Features,
      },
      {
        path:'notification',
        loader: notificationPageLoader,
        Component: NotificationPage,
      },
      {
        path:'categories',
        loader: categoryLoader,
        Component: Category,
      },
      {
        path:'contactus',
        loader: contactLoader,
        Component: ContactUs,
      },
      
      {
        path: 'dashboard',
        Component: DashboardLayout,
        children: [
          {   
            index: true,
            loader: myProfileLoader,
            Component: MyProfile
          },
          {   
            path: 'profile',
            loader: myProfileLoader,
            Component: MyProfile
          },
          {   
            path: 'courses',
            loader: myCoursesLoader,
            Component: MyCourses
          },
          {
            path:'settings',
            loader: settingLoader,
            Component: Setting,
          },
        ]
      },
      {
        path: 'documents',
        Component: PoliciesAgreementsLayout,
        children: [
          {   
            index: true,
            loader: privacyPolicyLoader,
            Component: PrivacyPolicy
          },
          {   
            path: 'privacy_policies',
            loader: privacyPolicyLoader,
            Component: PrivacyPolicy
          },
          {   
            path: 'terms_conditions',
            loader: termsAndConditionsLoader,
            Component: TermsAndConditions
          },
          {   
            path: 'instructor_agreement',
            loader: instructorAgreementLoader,
            Component: InstructorAgreement
          },
          {   
            path: 'venue_provider_agreement',
            loader: venueProviderAgreementLoader,
            Component: VenueProviderAgreement
          },
          {   
            path: 'payment_processing_agreement',
            loader: paymentProcessingAgreementLoader,
            Component: PaymentProcessingAgreement
          },
          
        ]
      },
      {
        path:'courses',
        children: [
          {   
            index: true,
            loader: coursesLoader,
            Component: Courses
          },
          {   
            path: ':courseid',
            loader: courseDetailLoader,
            Component: CourseDetail
          }
        ]
        // loader: coursesLoader,
        // Component: Courses,
      },
      {
        path:'chat',
        loader: chatLoader,
        Component: Chat,
      },
      {
        path:'forums',
        loader: forumLoader,
        Component: Forum,
      },
    ],
  },
  {
    path: "/auth",
    Component: AuthLayout,
    children: [
      {
        index: true,
        loader: authLoader,
        Component: Auth,
      },
      {
        path:'signin',
        loader: signinLoader,
        Component: Signin,
      },
      {
        path:'password',
        loader: passwordLoader,
        Component: Password,
      },
      {
        path:'setpassword',
        loader: newPasswordLoader,
        Component: NewPassword,
      },
      {
        path:'forgot',
        loader: forgotPasswordLoader,
        Component: ForgotPassword,
      },
      {
        path:'verification',
        loader: verificationLoader,
        Component: Verification,
      }
      
    ],
  },
  {
    path: "/onboarding",
    Component: OnBoardingLayout,
    children: [
      {
        index: true,
        loader: OnBoardingLoader,
        Component: OnBoarding,
      }
    ],
  },
  {
    path: "/final",
    Component: FullScreenMessageLayout,
    children: [
      {
        index: true,
        loader: authFinalLoader,
        Component: AuthFinal,
      },
      {
        path:'onboarding',
        loader: onBoardingFinalLoader,
        Component: OnBoardingFinal,
      }
    ],
  }
]);



export const App = () => {
  return <Provider store={store}><RouterProvider router={router} /></Provider>;
};

export default App;
