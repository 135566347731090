import React from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { useState } from "react";
import { Drawer } from "../../../components/ui-components";
import rightArrow from "../../../assets/svgs/rightArrow.svg";

function Remider() {
  const [open, setOpen] = useState(false);
  const [checkedState, setCheckedState] = useState({
    classTime: true,
    assignment: false,
    feedback: false,
    payment: true,
  });
  const [enabled, setEnabled] = useState(false);
  // console.log("checkedState", checkedState);

  return (
    <>
      <div
        className="w-full flex flex-row justify-between items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-8"
        onClick={() => setOpen(true)}>
        <div className="w-full flex flex-row justify-between gap-2">
          <p className="text-base text-primary">Reminders</p>
          <img src={rightArrow} alt="" />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Reminders">
        <div className="max-w-96 flex flex-col gap-5 my-5 mx-5">
          <div className="w-full flex flex-row justify-between gap-4">
            <p className="text-sm text-primary">Class start times</p>
            <Switch
              checked={checkedState.classTime}
              onChange={(checked) =>
                setCheckedState({ ...checkedState, classTime: checked })
              }
              className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
          </div>
          <div className="w-full flex flex-row justify-between gap-4">
            <p className="text-sm text-primary">Upcoming assignments or tasks</p>
            <Switch
              checked={checkedState.assignment}
              onChange={(checked) =>
                setCheckedState({ ...checkedState, assignment: checked })
              }
              className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
          </div>
          <div className="w-full flex flex-row justify-between gap-4">
            <p className="text-sm text-primary">Instructors feedback or grades</p>
            <Switch
              checked={checkedState.feedback}
              onChange={(checked) =>
                setCheckedState({ ...checkedState, feedback: checked })
              }
              className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
          </div>
          <div className="w-full flex flex-row justify-between gap-4">
            <p className="text-sm text-primary">Notify users of upcoming installment payments</p>
            <Switch
              checked={checkedState.payment}
              onChange={(checked) =>
                setCheckedState({ ...checkedState, payment: checked })
              }
              className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Remider;
