import React from "react";
import { useState } from "react";
import rightArrow from "../../../assets/svgs/rightArrow.svg";
import {
  Button,
  Drawer,
  DropDownField,
} from "../../../components/ui-components";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";

function SettingNotification() {
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [checkedState, setCheckedState] = useState({
    upcomingCourseDates: true,
    cancellation: false,
    newBatch: true,
  });

  return (
    <>
      <div
        className="w-full flex flex-row justify-between items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-8"
        onClick={() => setOpen(true)}>
        <div className="w-full flex flex-row justify-between gap-2">
          <p className="text-base text-primary">Notifications</p>
          <img src={rightArrow} alt="" />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Notifications">
        <form action="" className="w-full flex flex-col justify-between h-full">
          {/* <div className="flex flex-col gap-4">
                <DropDownField label="Course Updates" name="courseUpdates" options={[{key:"1",value:"Yes"},{key:"2",value:"No"}]} />
                <DropDownField label="Assignment / Task Notifications" name="assignments" options={[{key:"1",value:"Yes"},{key:"2",value:"No"}]} />
                <DropDownField label="Messages" name="messages" options={[{key:"1",value:"Yes"},{key:"2",value:"No"}]} />
                <DropDownField label="Offers & Promotions" name="offers" options={[{key:"1",value:"No"},{key:"2",value:"Yes"}]} />
                <DropDownField label="Email, SMS & Push Notifications" name="courseUpdates" options={[{key:"1",value:"No"},{key:"2",value:"Yes"}]} />
            </div> */}

          <div className="flex flex-col gap-4 max-w-full">
            {/* Course Updates */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-xl bg-white px-3 py-6 font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-base">
                  Courses Updates
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 size-5 text-gray-400"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="relative right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1 flex flex-col gap-4 px-4 py-4">
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Upcoming course dates and times
                      </p>
                      <Switch
                        checked={checkedState.upcomingCourseDates}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            upcomingCourseDates: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Cancellations or rescheduling
                      </p>
                      <Switch
                        checked={checkedState.cancellation}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            cancellation: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        New batch announcements
                      </p>
                      <Switch
                        checked={checkedState.newBatch}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            newBatch: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
            
            {/* Assignment / Task Notifications */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-xl bg-white px-3 py-6 font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-base">
                  Assignment / Task Notifications
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 size-5 text-gray-400"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="relative right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1 flex flex-col gap-4 px-4 py-4">
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Upcoming course dates and times
                      </p>
                      <Switch
                        checked={checkedState.upcomingCourseDates}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            upcomingCourseDates: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Cancellations or rescheduling
                      </p>
                      <Switch
                        checked={checkedState.cancellation}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            cancellation: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  
                </div>
              </MenuItems>
            </Menu>

            {/* Messages */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-xl bg-white px-3 py-6 font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-base">
                  Messages
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 size-5 text-gray-400"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="relative right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1 flex flex-col gap-4 px-4 py-4">
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Upcoming course dates and times
                      </p>
                      <Switch
                        checked={checkedState.upcomingCourseDates}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            upcomingCourseDates: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Cancellations or rescheduling
                      </p>
                      <Switch
                        checked={checkedState.cancellation}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            cancellation: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  
                </div>
              </MenuItems>
            </Menu>

            {/* Offers & Promotions */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-xl bg-white px-3 py-6 font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-base">
                  Offers & Promotions
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 size-5 text-gray-400"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="relative right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1 flex flex-col gap-4 px-4 py-4">
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Upcoming course dates and times
                      </p>
                      <Switch
                        checked={checkedState.upcomingCourseDates}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            upcomingCourseDates: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  
                  
                </div>
              </MenuItems>
            </Menu>

            {/* Email, SMS & Push Notifications */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-xl bg-white px-3 py-6 font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-base">
                  Courses Updates
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 size-5 text-gray-400"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="relative right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1 flex flex-col gap-4 px-4 py-4">
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Upcoming course dates and times
                      </p>
                      <Switch
                        checked={checkedState.upcomingCourseDates}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            upcomingCourseDates: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        Cancellations or rescheduling
                      </p>
                      <Switch
                        checked={checkedState.cancellation}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            cancellation: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        New batch announcements
                      </p>
                      <Switch
                        checked={checkedState.newBatch}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            newBatch: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="max-w-96 flex flex-row justify-between gap-4">
                      <p className="text-sm text-primary">
                        New batch announcements
                      </p>
                      <Switch
                        checked={checkedState.newBatch}
                        onChange={(checked) =>
                          setCheckedState({
                            ...checkedState,
                            newBatch: checked,
                          })
                        }
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-black">
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>

          </div>
        </form>
      </Drawer>
    </>
  );
}

export default SettingNotification;
