import { Fragment, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import CourseTitle from "./CourseTitle";
import AboutCourse from "./AboutCourse";
import Curriculum from "./Curriculum";

export async function courseDetailLoader(){
  return {
    date: new Date().toISOString(),
  };
}


export default function CourseDetail() {
 
  let data = useLoaderData();
  return (
    <div className='container mx-auto p-4 space-y-6'>
      <CourseTitle />
      <AboutCourse />
      <Curriculum />
      <div className="p-6 bg-gray-200">
        <h3 className="text-lg font-bold">Newsletter</h3>
        <form className="mt-4 flex">
          <input
            type="email"
            placeholder="Enter your email"
            className="flex-grow border px-4 py-2 rounded-l"
          />
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r">
            Subscribe
          </button>
        </form>
      </div>
    </div>
   
  );
}