import React from "react";
import CategoryMiddle from "./CategoryMiddle";
import CategoryVideo from "./CategoryVideo";
import { Container } from "../../components/ui-components";

const navigation = [
  { name: "Development", href: "#", count: "(230)", current: true },
  { name: "Business", href: "#", count: "(120)", current: false },
  { name: "Finance & Accounting", href: "#", count: "(12)", current: false },
  { name: "IT & Software", href: "#", count: "(210)", current: false },
  { name: "Personal Development", href: "#", count: "(121)", current: false },
  { name: "Design", href: "#", count: "(145)", current: false },
  { name: "Marketing", href: "#", count: "(550)", current: false },
  { name: "lifestyle", href: "#", count: "(65)", current: false },
  { name: "Health & Fitness", href: "#", count: "(90)", current: false },
  { name: "Music", href: "#", count: "(101)", current: false },
  { name: "Teaching & Academics", href: "#", count: "(101)", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export async function categoryLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function Category() {
  return (
    <>
      <section className="py-10 border-b border-b-gray-5">
        <Container className="max-w-full">
          <h2 className="text-2xl text-text font-bold pl-5">
            Explore All Categories
          </h2>
        </Container>
      </section>
      <section className="border-b border-b-gray-5">
        <Container className="max-w-full">
          <div className="flex flex-row">
            <div className="w-[70%] flex ">
              <div className="w-full flex flex-row">
                <nav
                  aria-label="Sidebar"
                  className="w-[30%] flex flex-col border-r-[1px] py-8 px-3 gap-4 overflow-y-auto">
                  <h2 className="text-base font-semibold mx-2">
                    Browse by category
                  </h2>
                  <ul role="list" className="space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-200 text-primary font-bold text-lg"
                              : "text-gray-3 hover:bg-gray-200 hover:text-primary hover:font-bold",
                            "group flex flex-row items-center rounded-md p-2 pl-3 text-sm text-gray-400 whitespace-nowrap"
                          )}>
                          {item.name}
                          {item.count && (
                            <span
                              aria-hidden="true"
                              className=" w-9 min-w-max whitespace-nowrap rounded-full px-2 py-1 text-center text-sm font-medium text-gray-600">
                              {item.count}
                            </span>
                          )}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                <CategoryMiddle />
              </div>
            </div>
            <div className="w-[30%] flex flex-col p-6">
              <CategoryVideo />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Category;
