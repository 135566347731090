
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import DashboardSideBar from './sidebar';

const DashboardLayout = () => {
    let location = useLocation();
    const [isProfile, setIsProfile] = useState(false);

    useEffect(() => {
    // console.log("checkHome", location);
    if (location && location.pathname == "/dashboard/profile" || location.pathname == "/dashboard") {
        setIsProfile(true);
    }
    }, []);

    return (
        <div className="bg-white flex flex-row items-start justify-start">
            <div className={`flex h-[calc(100vh-80px)] sticky top-0 ${isProfile?'w-16':'w-80'}`}>
                <DashboardSideBar isProfile={isProfile} />
            </div>
            <div className='flex w-full'>
                <Outlet />
            </div>  
        </div>
  );
};

export default DashboardLayout;
