import actiontyps from "./actionsTypes";
const { AUTH_BEGIN, AUTH_SUCCESS, AUTH_ERR } = actiontyps; 
const actions = {
  
  checkUserBegin: () => {
    return {
      type: AUTH_BEGIN,
    };
  },

  checkUserSuccess: (data) => {
    return {
      type: AUTH_SUCCESS,
      data,
    };
  },

  checkUserErr: (err) => {
    return {
      type: AUTH_ERR,
      err,
    };
  },

  loginBegin: () => {
    return {
      type: AUTH_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: AUTH_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: AUTH_ERR,
      err,
    };
  },
};


export default actions;
