import React, { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
// import Categories from '../../components/shared-components/CategoryPopover';
import Search from "../../components/shared-components/GlobalSearch";
import { Logo } from "../../components/shared-components/Logo";
import { Container, Button, NavLink } from "../../components/ui-components";
import {
  CategoriesIcon,
  ChatIcon,
  ForumIcon,
  GlobalReach,
  HomeIcon,
  NotificationIcon,
} from "../../components/icons";
import { Link, useLocation } from "react-router-dom";
import HeaderSearch from "../../components/shared-components/GlobalSearch";
import UserMenu from "./UserMenu";
// import UserMenuLoggedOut from "./UserMenuLoggedOut";


const navigations = [
  {
    name: "Home",
    icon: <HomeIcon />,
    link:'/',
  },
  {
    name: "Categories",
    icon: <CategoriesIcon />,
    link:'/categories',
  },    
  {
    name: "Chat",
    icon: <ChatIcon />,
    link:'/chat',
  },
  {
    name: "Forum",
    icon: <ForumIcon />,
    link:'/forums',
  },
  {
    name: "Notification",
    icon: <NotificationIcon />,
    link:'/notification',
  }
];

const SiteHeader = () => {
  let location = useLocation();
  const [isHome, setIsHome] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    console.log("checkHome", location);
    if (location && location.pathname == "/") {
      setIsHome(true);
    }else{
      setIsHome(false);
    }
  }, [location]);

  return (
    <>
      {/* <div className="hidden md:flex md:gap-x-6">
    <Categories />
    </div>
    <Search /> */}

      <header
        className={`py-2 border-0 border-b-2 brdder-b-gray-1 ${
          isHome ? "bg-gray-6" : "bg-white"
        }`}>
        <Container style={{ width: "100%", maxWidth: "100%" }}>
          <nav className="relative z-50 flex justify-between gap-5">
            <div className="flex justify-start items-center gap-5 w-full">
              <a href="#" aria-label="Home">
                <Logo className="h-10 w-auto" />
              </a>
              {isHome ? null : <HeaderSearch />}
            </div>
            <div className="flex justify-between items-center gap-5 w-full">
              <div
                className={`flex items-start w-full ${
                  isHome ? "justify-center" : "justify-end"
                }`}>
                <div className="flex flex-row justify-between items-baseline gap-8">
                  {
                    navigations.map((navItem)=>(
                      <Link to={navItem.link} className={`flex flex-col justify-center items-center p-1 gap-1 ${location.pathname == navItem.link?'opacity-100 font-semibold':'opacity-60'}`}>
                        {navItem.icon}
                        <span className="text-text font-normal text-center text-base">
                          {navItem.name}
                        </span>
                      </Link>
                    ))
                  }
                </div>
              </div>
              <div className="flex items-center justify-end">
                <UserMenu HomePage={isHome} />
              </div>
            </div>
          </nav>
        </Container>
      </header>

      {/* {isLoggedIn ? <UserMenu /> : <UserMenuLoggedOut isLoggedIn={isLoggedIn} />} */}
      
    </>
  );
};

export default SiteHeader;
