import React, { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
// import { Button, Divider, Form, FormProps, Input, Select, Space } from 'antd';
import { useDispatch } from 'react-redux';
// import { useForm } from "react-hook-form";
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { checkUser, checkUserType } from '../../redux/authentication/actionCreator';
import GoogleButton from '../../components/shared-components/GoogleButton';
import AppleButton from '../../components/shared-components/AppleButton';
import AuthFooter from '../../components/shared-components/AuthFooter';
import { BackArrow, CircleOutlineIcon } from '../../components/icons';
import { Button, Divider, TextField, FloatingTextField, Spinner } from '../../components/ui-components';

// CheckCircleIcon   XMarkIcon XCircleIcon 
export async function newPasswordLoader() {
  // await sleep();
  return {
    date: new Date().toISOString(),
  };
}
  
const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const { register, handleSubmit, formState: { errors } } = useForm();
  const [username, setUsername] = useState('');
  const [newUser, setNewUser] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
  });
  const [passwordRules, setPasswordRules] = useState({
    strength: false,
    name: false,
    minleangth: false,
    number_symbol: false
  });
  const [errors, setErrors] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);  
  let data = useLoaderData();

  const validate = () => {
    const newErrors = {};
    // Password validation
    if (!formData.password) {
        newErrors.password = 'Please enter your password.';
    }

    // Password validation
    // if (!formData.password) {
    //     newErrors.password = 'Password is required.';
    // } else if (formData.password.length < 6) {
    //     newErrors.password = 'Password must be at least 6 characters.';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const onFinish = async (e) => {
    e.preventDefault();
    setFormSubmit(true);
    // console.log('sasasassasa');
    setTimeout(() => {
      if (validate()) {
          // alert('Form submitted successfully!');
          console.log(formData);
          navigate('/final');
          setFormSubmit(false);
      }else{
        setFormSubmit(false);
      }
    }, 2000);
    // console.log('values', values);
    // 
    
    // values['type'] = fieldStatus?'phone':'email';
    // let reponse = await dispatch(checkUser(values));
    // console.log("reponse", reponse)
    
    // if(reponse){
    //   if(reponse.type === 'AUTH_SUCCESS'){
    //     if(reponse.data.newUser){
    //       navigate('/auth/verification',{state:values});
    //     }else{
    //       navigate('/auth/password',{state:values});
    //     }
    //   }else{
        
    //   }
    // }else{

    // }    
  };

  const passwordRulesCheck = (password) => {
    const lengthRule = password.length >= 8;
    const uppercaseRule = /[A-Z]/.test(password);
    const lowercaseRule = /[a-z]/.test(password);
    const numberRule = /\d/.test(password);
    const specialCharRule = /[!@#$%^&*]/.test(password);
    setPasswordRules({
      strength: uppercaseRule && lowercaseRule,
      name: uppercaseRule && lowercaseRule,
      minleangth: lengthRule,
      number_symbol: numberRule && specialCharRule
    });
  //   // Update UI
  //   document.getElementById("length").className = lengthRule
  //   ? "valid"
  //   : "invalid";
  // document.getElementById("uppercase").className = uppercaseRule
  //   ? "valid"
  //   : "invalid";
  // document.getElementById("lowercase").className = lowercaseRule
  //   ? "valid"
  //   : "invalid";
  // document.getElementById("number").className = numberRule
  //   ? "valid"
  //   : "invalid";
  // document.getElementById("special").className = specialCharRule
  //   ? "valid"
  //   : "invalid";
  }

  // useEffect(()=>{
  //   if(location && location.state){
  //     // console.log('location.state', location.state);
  //     setUsername(location.state.username);
  //     setNewUser(location.state.new_user);
  //   }else{
  //     navigate('/auth/signin');
  //   }
  // },[]);

  const handleChange = (e) => {
    // console.log('e.target', );
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
    passwordRulesCheck(value);
    e.target.autofocus = true;
  };

  return (
    <div className='flex flex-col justify-start items-start gap-4'>
      <h3 className='text-2xl text-text text-left w-full font-medium'>{'Set your password'}</h3>
      <p className="text-base text-text text-left w-full">Your OTP has been verified. Now, please set your password to get started on SimpliTrain.</p>
      <form  onSubmit={onFinish} method="POST" className="w-full">
        <FloatingTextField label="Set Password" type={'password'} name={"password"} id={"password"} onChange={handleChange} placeholder="Set Password" error={errors.password} />
        <ul className='flex flex-col items-start justify-start w-full m-0 my-5 p-1'>
          <li className='flex flex-row justify-start items-center gap-2'>
            <span>{passwordRules.strength?<CheckCircleIcon className='w-5' />:<CircleOutlineIcon />}</span>
            <span>Password strength: weak</span>
          </li>
          <li className='flex flex-row justify-start items-center gap-2'>
            <span>{passwordRules.name?<CheckCircleIcon className='w-5' />:<CircleOutlineIcon />}</span>
            <span>Can't contain your name or email address</span>
          </li>
          <li className='flex flex-row justify-start items-center gap-2'>
            <span>{passwordRules.minleangth?<CheckCircleIcon className='w-5' />:<CircleOutlineIcon />}</span>
            <span>At least 8 characters</span>
          </li>
          <li className='flex flex-row justify-start items-center gap-2'>
            <span>{passwordRules.number_symbol?<CheckCircleIcon className='w-5' />:<CircleOutlineIcon />}</span>
            <span>Contains a number or symbol</span>
          </li>
        </ul>
        <Button type='submit' color='primary' variant='solid' className={formSubmit?'w-20':'w-full'} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'Save & Continue'}</Button>
      </form>
    </div>
  )
}

export default NewPassword;