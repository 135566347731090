import React from "react";

function ContactInfo() {
  return (
    <>
      <div className="border border-gray-200 rounded-xl my-4">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">
          Contact Information
        </h3>

        <div className="flex flex-row border-b border-gray-200 px-6 pb-3">
          <div className="w-1/3 flex items-center">
            <p className="text-gray-600 text-xs">EMAIL</p>
          </div>
          <p className="font-semibold overflow-hidden text-sm">Rakeshkumar420@gmail.com</p>
        </div>
        <div className="flex flex-row border-gray-200 px-6 py-3">
          <div className="w-1/3 ">
            <p className="text-gray-500 text-xs">PHONE NUMBER</p>
          </div>
          <p className="font-semibold text-sm">+91 9999999999</p>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;
