import React from 'react';
import { useLoaderData } from "react-router-dom";
import { Container } from '../../../components/ui-components';
import Language from './Language';
import TimeZone from './TimeZone';
import LearningMode from './LearningMode';
import SettingNotification from './SettingNotification';
import Reminder from './Reminder';
// import DashboardLayout from '../dashboard';

export async function settingLoader(){
    return {
      date: new Date().toISOString(),
    };
  }

function Setting() {
    // const data = useLoaderData();

  return (
    <section className='py-6 w-full'>
        <Container>
            <h1 className='text-2xl font-semibold text-secondary'>Settings</h1>
            <div className='w-full max-w-xl flex flex-col gap-4'>
                <Language />
                <TimeZone />
                <LearningMode />
                <SettingNotification />
                <Reminder />
            </div>
        </Container>
    </section>

  )
}

export default Setting;
