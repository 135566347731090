import { combineReducers } from 'redux';
import AuthReducer from './authentication/reducers';
// import ChangeLayoutMode from './themeLayout/reducers';


const rootReducers = combineReducers({  
  auth: AuthReducer,
  // ChangeLayoutMode,
});

export default rootReducers;
