
import React, { useState } from 'react';
import clsx from 'clsx'
import { XMarkIcon } from '@heroicons/react/20/solid';


const FilterSideBar = ({ onClose }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="w-full bg-white border-r border-r-gray-5">
      <div className="flex py-4 px-5 justify-between items-center border-b border-b-gray-5 mb-4">
        <h2 className="text-sm uppercase text-gray-600 font-medium">Filter</h2>
        <button onClick={()=> onClose(false) } className="w-8 h-8"><XMarkIcon className='size-5' /></button>
      </div>
      <div className='flex flex-col gap-8'>
        {/* Ratings Section */}
        {/* <div className="px-5">
          <h3 className="text-sm font-medium mb-5">Ratings</h3>
          <ul className="space-y-2">
            {Array.from({ length: 6 }, (_, i) => (
              <li key={i} className="flex items-center space-x-2">
                <input type="radio" name="rating" id={`rating-${i}`} className="w-4 h-4" />
                <label htmlFor={`rating-${i}`} className="text-sm">
                  {5 - i}.0 & up
                </label>
              </li>
            ))}
          </ul>
        </div> */}

        {/* Price Slider */}
        <div className="px-5">
          <h3 className="text-sm font-medium mb-5">Price</h3>
          <input type="range" min="0" max="100" className="w-full accent-blue-500" />
        </div>

        {/* Course Level */}
        <div className="px-5">
          <h3 className="text-sm font-medium mb-5">Course Level</h3>
          <ul className="space-y-2">
            {["All Levels", "Beginner", "Intermediate", "Advanced"].map((level, idx) => (
              <li key={idx} className="flex items-center space-x-2">
                <input type="checkbox" id={`level-${idx}`} className="w-4 h-4" />
                <label htmlFor={`level-${idx}`} className="text-sm">{level}</label>
              </li>
            ))}
          </ul>
        </div>

        {/* Duration */}
        <div className="px-5">
          <h3 className="text-sm font-medium mb-5">Duration of the Course</h3>
          <ul className="space-y-2">
            {["Hours", "Days", "Weeks", "Months"].map((duration, idx) => (
              <li key={idx} className="flex items-center space-x-2">
                <input type="checkbox" id={`duration-${idx}`} className="w-4 h-4" />
                <label htmlFor={`duration-${idx}`} className="text-sm">{duration}</label>
              </li>
            ))}
          </ul>
        </div>

        {/* Show More Section */}
        {/* <div className="mb-6">
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-blue-500 hover:underline"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
          {showMore && (
            <div className="mt-4 space-y-4">
              <div>
                <h3 className="text-sm font-semibold mb-2">Instructor Type</h3>
                <ul className="space-y-2">
                  {["Celebrity Instructor", "Certified Instructor"].map((type, idx) => (
                    <li key={idx} className="flex items-center space-x-2">
                      <input type="checkbox" id={`instructor-${idx}`} className="w-4 h-4" />
                      <label htmlFor={`instructor-${idx}`} className="text-sm">{type}</label>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-sm font-semibold mb-2">Language Type</h3>
                <ul className="space-y-2">
                  {["All", "English", "Hindi", "Tamil", "Kannada", "Malayalam"].map((lang, idx) => (
                    <li key={idx} className="flex items-center space-x-2">
                      <input type="checkbox" id={`language-${idx}`} className="w-4 h-4" />
                      <label htmlFor={`language-${idx}`} className="text-sm">{lang}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div> */}
      </div>
    
    </div>
  );
};

export default FilterSideBar;
