import React from "react";
import earth from "../../assets/svgs/earth.svg";
import Boxes from "./Boxes";
import dollar from "../../assets/svgs/dollar.svg";
import { Container } from "../../components/ui-components";

function VenueProviders() {
  const venueProvidersContent = [
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
    {
      topic: "Flexible Learning Formats",
      body: "Choose From online, offline group classes, or one-on-one sessions based on your preferences.",
    },
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
    {
      topic: "Live interactive sessions",
      body: "Join real-time classes with experts instructors from around the World.",
    },
  ];

  const venueProvidersData = [
    {
      img: dollar,
      alt: "Earth Logo",
      title: "Sign Up & Create a Profile",
      body: "Register as a venue provider and create your venue’s profile, showcasing its location, capacity.",
    },
    {
      img: dollar,
      alt: "Notes Logo",
      title: "List Your Venue",
      body: "Add your venue to our platform, specifying available dates and types of events/classes (offline sessions etc.).",
    },
    {
      img: dollar,
      alt: "Assignments Logo",
      title: "Get Bookings",
      body: "Instructors browse your venue and book it for their offline sessions or group classes.",
    },
    {
      img: dollar,
      alt: "Save Logo",
      title: "Host Classes & Earn",
      body: "Welcome instructors and learners to your space, host live classes, and get paid securely through the platform.",
    },
  ];
  return (
    <>
      <section className="py-16">
        <Container>
          <div className="w-full max-w-[80%] grid grid-cols-3 m-auto my-10 gap-8">
            {venueProvidersContent.map((box, index) => (
              <Boxes box={box} index={index} />
            ))}
          </div>
        </Container>
      </section>
      <section className="py-16">
        <Container className={'max-w-full'}>
          <h1 className="lg:text-5xl text-center font-bold md:text-4xl mb-12">
           Benefits of being Venue Providers
          </h1>
          <div className="flex flex-row items-baseline justify-center gap-8">
            {venueProvidersData.map((ele, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center text-center max-w-72 gap-2 transform transition duration-300 hover:scale-110 hover: cursor-pointer ">
                <img className="w-12" src={ele.img} alt={ele.alt} />
                <h2 className="font-semibold lg:text-2xl leading-9 md:text-md whitespace-nowrap ">
                  {ele.title}
                </h2>
                <p className="lg:text-base md:text-sm lg:mx-2">{ele.body}</p>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <div className="py-0 pb-20">
            <div className="rounded-4xl py-40 px-8  bg-gray-4 ">
              <div className="text-center ">
                <h1 className=" text-6xl font-semibold tracking-tight text-gray-900 whitespace-nowrap">
                  Join Us Today as a Venue Provider
                </h1>
                <p className="mt-8 text-pretty text-xl font-medium text-gray-500 my-8">
                Partner with us by offering your venue for in-person learning experiences. Help connect instructors and learners while growing your business through SimpliTrain&#39;s network
                </p>
                <button
                  type="button"
                  className="rounded-md bg-black px-20 py-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default VenueProviders;
