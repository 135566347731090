import React from "react";

const Icon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0025 12C22.9025 12 21.9609 11.6083 21.1775 10.825C20.3942 10.0417 20.0025 9.1 20.0025 8C20.0025 6.9 20.3942 5.95833 21.1775 5.175C21.9609 4.39167 22.9025 4 24.0025 4C25.1025 4 26.0442 4.39167 26.8275 5.175C27.6109 5.95833 28.0025 6.9 28.0025 8C28.0025 9.1 27.6109 10.0417 26.8275 10.825C26.0442 11.6083 25.1025 12 24.0025 12ZM18.0025 42V18C16.3692 17.8667 14.7192 17.6833 13.0525 17.45C11.3859 17.2167 9.75252 16.9167 8.15252 16.55C7.58585 16.4167 7.12752 16.1 6.77752 15.6C6.42752 15.1 6.33585 14.5667 6.50252 14C6.66919 13.4333 7.01919 13.0167 7.55252 12.75C8.08585 12.4833 8.65252 12.4167 9.25252 12.55C11.5859 13.05 14.0109 13.4167 16.5275 13.65C19.0442 13.8833 21.5359 14 24.0025 14C26.4692 14 28.9609 13.8833 31.4775 13.65C33.9942 13.4167 36.4192 13.05 38.7525 12.55C39.3525 12.4167 39.9192 12.4833 40.4525 12.75C40.9859 13.0167 41.3359 13.4333 41.5025 14C41.6692 14.5667 41.5775 15.1 41.2275 15.6C40.8775 16.1 40.4192 16.4167 39.8525 16.55C38.2525 16.9167 36.6192 17.2167 34.9525 17.45C33.2859 17.6833 31.6359 17.8667 30.0025 18V42C30.0025 42.5667 29.8109 43.0417 29.4275 43.425C29.0442 43.8083 28.5692 44 28.0025 44C27.4359 44 26.9609 43.8083 26.5775 43.425C26.1942 43.0417 26.0025 42.5667 26.0025 42V32H22.0025V42C22.0025 42.5667 21.8109 43.0417 21.4275 43.425C21.0442 43.8083 20.5692 44 20.0025 44C19.4359 44 18.9609 43.8083 18.5775 43.425C18.1942 43.0417 18.0025 42.5667 18.0025 42Z" fill="white"/>
    </svg>

  );
};

export default Icon;
