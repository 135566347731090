
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    AUTH_BEGIN:'AUTH_BEGIN',
    AUTH_ERR:'AUTH_ERR',
    AUTH_SUCCESS:'AUTH_SUCCESS'
}

export default actiontyps;