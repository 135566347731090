import React from "react";

const Icon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9 32.05C18.9313 32.05 19.8142 31.6877 20.5485 30.963C21.2828 30.2383 21.65 29.3673 21.65 28.35C21.65 27.3327 21.2828 26.4617 20.5485 25.737C19.8142 25.0123 18.9313 24.65 17.9 24.65C16.8963 24.65 16.037 25.0123 15.322 25.737C14.6073 26.4617 14.25 27.3327 14.25 28.35C14.25 29.3673 14.6073 30.2383 15.322 30.963C16.037 31.6877 16.8963 32.05 17.9 32.05ZM30.1 32.05C31.1177 32.05 31.9887 31.6877 32.713 30.963C33.4377 30.2383 33.8 29.3673 33.8 28.35C33.8 27.3327 33.4377 26.4617 32.713 25.737C31.9887 25.0123 31.1177 24.65 30.1 24.65C29.0823 24.65 28.2113 25.0123 27.487 25.737C26.7623 26.4617 26.4 27.3327 26.4 28.35C26.4 29.3673 26.7623 30.2383 27.487 30.963C28.2113 31.6877 29.0823 32.05 30.1 32.05ZM23.998 21.35C25.0297 21.35 25.913 20.9827 26.648 20.248C27.3827 19.513 27.75 18.6297 27.75 17.598C27.75 16.566 27.3828 15.7 26.6485 15C25.9142 14.3 25.0313 13.95 24 13.95C22.9963 13.95 22.137 14.3073 21.422 15.022C20.7073 15.737 20.35 16.5963 20.35 17.6C20.35 18.6313 20.7 19.5142 21.4 20.2485C22.1 20.9828 22.966 21.35 23.998 21.35ZM24.0135 44C21.2555 44 18.6638 43.475 16.2385 42.425C13.8128 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.186 5.575 31.758C4.525 29.33 4 26.7357 4 23.975C4 21.2143 4.525 18.62 5.575 16.192C6.625 13.764 8.05833 11.65 9.875 9.85C11.6917 8.05 13.814 6.625 16.242 5.575C18.67 4.525 21.2643 4 24.025 4C26.7857 4 29.38 4.525 31.808 5.575C34.236 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2288 44 23.9865C44 26.7445 43.475 29.3362 42.425 31.7615C41.375 34.1872 39.95 36.3053 38.15 38.116C36.35 39.9263 34.2333 41.3597 31.8 42.416C29.3667 43.472 26.7712 44 24.0135 44ZM24.025 41C28.7417 41 32.75 39.3417 36.05 36.025C39.35 32.7083 41 28.6917 41 23.975C41 19.2583 39.3532 15.25 36.0595 11.95C32.7658 8.65 28.746 7 24 7C19.3 7 15.2917 8.64683 11.975 11.9405C8.65833 15.2342 7 19.254 7 24C7 28.7 8.65833 32.7083 11.975 36.025C15.2917 39.3417 19.3083 41 24.025 41Z" fill="white"/>
    </svg>


  );
};

export default Icon;
