import React from "react";
import { EditPencilTwo } from "../../../components/icons";
import { useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Drawer, EditableTagField } from "../../../components/ui-components";


function Topics() {
  const [open, setOpen] = useState(false);
  const [userTopics, setUserTopics] = useState(['Web Design', 'HTML']);
  return (
    <>
      <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">
          Interested Topics
        </h3>
        <button className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110" onClick={()=>setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <div className="flex flex-row mx-6 mb-4 gap-4 flex-wrap">
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Web Development
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Mobile Development
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Programming Languages
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Leadership
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Career Development
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Digital Marketing
          </button>
        </div>
      </div>

      <Drawer open={open} onClose={setOpen}>
        <EditableTagField name={'topics'} id={'topics'} placeholder={'Enter Topics'} values={userTopics} onChange={setUserTopics} />
      </Drawer>
      
    </>
  );
}

export default Topics;
