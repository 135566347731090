// AuthFooter
const AuthFooter = () => {
//   const { t } = useTranslation('authIntroComponent');

  return (
    <p className="text-center max-w-[340px]">By proceeding you agree to our Privacy Policy and Terms ans conditions.</p>
  );
};

export default AuthFooter;
