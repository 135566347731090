import { Fragment, } from 'react'
import { useLoaderData } from "react-router-dom";
import InnerHeroSection from '../../components/shared-components/InnerHeroSection';
import { Container } from '../../components/ui-components';
import Commitment from './Commitment';
import Support from './Support';
import ExpertSupport from './ExpertSupport';


export async function contactLoader(){
  return {
    date: new Date().toISOString(),
  };
}


export default function ContactUs() {
 
  let data = useLoaderData();
  return (
    <>
      {/* Hero Section */}
      <section className="bg-gray-100 py-20">
        <Container>
          <InnerHeroSection heading={'Contact Us All Your Learning Needs!'}  />
        </Container>
      </section>

      <section>
        <Container>
          <Commitment />
        </Container>
      </section>

      <section>
        <Container>
          <Support />
        </Container>
      </section>

      <section>
        <ExpertSupport />
      </section>
      
    </>
  );
}