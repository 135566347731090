import React from "react";

const navigation = [
  { name: "Web Development", href: "#", count: "(100)", current: false },
  { name: "Data Science", href: "#", count: "(120)", current: false },
  { name: "Mobile Development", href: "#", count: "(12)", current: false },
  { name: "Programming Language", href: "#", count: "(210)", current: false },
  { name: "Database Design", href: "#", count: "(121)", current: false },
  { name: "Software Engineering", href: "#", count: "(145)", current: false },
  { name: "Software Development", href: "#", count: "(550)", current: false },
  { name: "No-code Development", href: "#", count: "(65)", current: false },
  { name: "Game Development", href: "#", count: "(90)", current: false },
  { name: "Software Testing", href: "#", count: "(101)", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CategoryMiddle() {
  return (
    <>
      <nav
        aria-label="Sidebar"
        className="w-[70%] flex flex-col px-6 py-8 gap-4 overflow-y-auto border-r-[1px]">
        <h2 className="text-base font-semibold mx-2">Development (230)</h2>
        <ul role="list" className=" h-[40%] flex flex-col w-full flex-wrap">
          {navigation.map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                className={classNames(
                  item.current
                    ? "bg-gray-200 text-primary fold-bold text-lg"
                    : "text-gray-4 hover:font-bold",
                  "group flex rounded-md p-2 text-base text-gray-500 whitespace-nowrap"
                )}>
                {item.name}
                {item.count ? (
                  <span
                    aria-hidden="true"
                    className="gap-x-1 w-9 min-w-max whitespace-nowrap rounded-full px-1 py-0.5 text-center text-sm font-medium text-gray-600">
                    {item.count}
                  </span>
                ) : null}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}

export default CategoryMiddle;
