import React from "react";

const Icon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 24.05C26.2 24.05 28.45 23.9083 30.75 23.625C33.05 23.3417 35.2 22.9167 37.2 22.35C37.9 22.0833 38.5417 22.1667 39.125 22.6C39.7083 23.0333 40 23.6167 40 24.35V38.7C40 39.3223 39.8233 39.8885 39.47 40.3985C39.117 40.9088 38.6603 41.276 38.1 41.5C36 42.3333 33.6833 42.9583 31.15 43.375C28.6167 43.7917 26.2333 44 24 44C21.7667 44 19.3833 43.7917 16.85 43.375C14.3167 42.9583 12 42.3333 9.9 41.5C9.33967 41.276 8.883 40.9088 8.53 40.3985C8.17667 39.8885 8 39.3223 8 38.7V24.35C8 23.6167 8.29167 23.0333 8.875 22.6C9.45833 22.1667 10.1 22.0833 10.8 22.35C12.8 22.9167 14.95 23.3417 17.25 23.625C19.55 23.9083 21.8 24.05 24 24.05ZM37 38.5V25.35C35.2333 25.8167 33.1618 26.2167 30.7855 26.55C28.4095 26.8833 26.1512 27.05 24.0105 27.05C21.8702 27.05 19.6083 26.8833 17.225 26.55C14.8417 26.2167 12.7667 25.8167 11 25.35V38.5C12.6333 39.1667 14.6917 39.75 17.175 40.25C19.6583 40.75 21.9333 41 24 41C26.0667 41 28.3417 40.75 30.825 40.25C33.3083 39.75 35.3667 39.1667 37 38.5ZM24 4C26.1667 4 27.9917 4.74167 29.475 6.225C30.9583 7.70833 31.7 9.53333 31.7 11.7C31.7 13.8667 30.9583 15.6917 29.475 17.175C27.9917 18.6583 26.1667 19.4 24 19.4C21.8333 19.4 20.0083 18.6583 18.525 17.175C17.0417 15.6917 16.3 13.8667 16.3 11.7C16.3 9.53333 17.0417 7.70833 18.525 6.225C20.0083 4.74167 21.8333 4 24 4ZM24.008 16.4C25.3027 16.4 26.4083 15.939 27.325 15.017C28.2417 14.095 28.7 12.9867 28.7 11.692C28.7 10.3973 28.239 9.29167 27.317 8.375C26.395 7.45833 25.2867 7 23.992 7C22.6973 7 21.5917 7.461 20.675 8.383C19.7583 9.305 19.3 10.4133 19.3 11.708C19.3 13.0027 19.761 14.1083 20.683 15.025C21.605 15.9417 22.7133 16.4 24.008 16.4Z" fill={props.color} />
    </svg>
    

  );
};

export default Icon;






