import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";
import GoogleButton from '../../components/shared-components/GoogleButton';
import AppleButton from '../../components/shared-components/AppleButton';
import AuthFooter from '../../components/shared-components/AuthFooter';
import { Button, Divider, Spinner } from '../../components/ui-components';
import clsx from 'clsx'
import { XMarkIcon } from '@heroicons/react/24/outline';
import Step1 from './Step1';
import Step4 from './Step4';
import Step3 from './Step3';
import Step2 from './Step2';

const tagCss = 'text-base font-normal px-4 py-[0.3rem] rounded-full border border-text';

export async function OnBoardingLoader() {
  
  // await sleep();
  return {
    date: new Date().toISOString(),
  };
}

const StepData = {
  step1:{
    title:'What would you like to learn?',
    info:'Pick the skills or areas you\'d like to explore'
  },
  step2:{
    title:'What is your immediate goal?',
    info:'Select your current focus to personalize your journey'
  },
  step3:{
    title:'What is your preferred learning style?',
    info:'Choose how you\'d prefer to engage with your learning'
  },
  step4:{
    title:'Let\'s Personalize \n Your Learning Journey',
    info:''
  }
}


const OnBoarding = () => {
  const navigate = useNavigate();
  const [formSubmit, setFormSubmit] = useState(false);  
  const [step, setStep] = useState(1);
  const [onBoardingData, setOnBoardingData] = useState({
    skills:[],
    immediate_goal:[],
    learning_style:[],
    personal_details:{
      full_name:'',
      gender:'',
      age:''
    }
  });
 
  const SkilsList = ['IT', 'Design', 'Marketing', 'Business', 'IT1', 'Design1', 'Marketing1', 'Business1', 'IT2', 'Design2', 'Marketing2', 'Business2', 'IT3', 'Design3', 'Marketing3', 'Business3'];
  const GoalsList = ['Become better at what I am doing', 'Learn a new skill', 'Career Transition', 'Get a job', 'Learn a hubby', 'Get a job1'];
  const learningStylesList = ['Online', 'Classroom', 'One on One'];
  const ageGroups = ['<20', '21 - 30', '31 - 40', '41 - 50', '51 - 60', '>61'];
  let data = useLoaderData();

  const addRemoveActionSkills = (val) => {
    let skills = [...onBoardingData.skills];
    // const checkExist = skills.indexOf(el=> el.lowercase() == val.lowercase());
    if(skills.includes(val)){
      const newArr = skills.filter(el => el != val);
      skills = newArr;
    }else{
      skills.push(val);
    }
    setOnBoardingData({
      ...onBoardingData,
      skills:[...skills]
    });
  }

  const addRemoveActionGoals = (val) => {
    let immediate_goal = [...onBoardingData.immediate_goal];
    // const checkExist = skills.indexOf(el=> el.lowercase() == val.lowercase());
    if(immediate_goal.includes(val)){
      const newArr = immediate_goal.filter(el => el != val);
      immediate_goal = newArr;
    }else{
      immediate_goal.push(val);
    }
    setOnBoardingData({
      ...onBoardingData,
      immediate_goal:[...immediate_goal]
    });
  }

  const addRemoveActionLearningStyles = (val) => {
    let learning_style = [...onBoardingData.learning_style];
    // const checkExist = skills.indexOf(el=> el.lowercase() == val.lowercase());
    if(learning_style.includes(val)){
      const newArr = learning_style.filter(el => el != val);
      learning_style = newArr;
    }else{
      learning_style.push(val);
    }
    setOnBoardingData({
      ...onBoardingData,
      learning_style:[...learning_style]
    });
  }

  const addRemoveActionData = (Obj) => {
    console.log('Obj', Obj);
    setOnBoardingData({
      ...onBoardingData,
      personal_details:{...Obj}
    });
  }
  
  const stepChange = (st) => {
    setStep(Number(step)+Number(st));
    // console.log('onBoardingData', onBoardingData);
  }

  const checkAdded = (val) => {
    let skills = [...onBoardingData.skills];
    if(skills.includes(val)){
      return 'bg-primary text-white';
    }else{
      return 'bg-white text-text';
    }
  }

  const FinalStepSubmit = () => {
    console.log('onBoardingData', onBoardingData);
    navigate('/final/onboarding');
  }

  return (
    <div className='relative h-full flex flex-col'>
      <div className='absolute w-full left-0 -top-8 flex justify-center items-center'>
        <p className='flex flex-row justify-center items-center gap-2'>
          <span className={`w-14 h-[2px] bg-gray-4 ${step >= 1?'bg-primary':''}`}></span>
          <span className={`w-14 h-[2px] bg-gray-4 ${step >= 2?'bg-primary':''}`}></span>
          <span className={`w-14 h-[2px] bg-gray-4 ${step >= 3?'bg-primary':''}`}></span>
          <span className={`w-14 h-[2px] bg-gray-4 ${step >= 4?'bg-primary':''}`}></span>
        </p>
      </div>
      <div className='flex flex-col h-full justify-between items-center gap-2 p-10'>
        <div className='flex flex-col justify-center items-center'>
          <h3 className='text-2xl text-text font-medium'>{StepData[`step${step}`].title}</h3>
          <p className='text-base text-text font-normal'>{StepData[`step${step}`].info}</p>
        </div>
        <div className='flex flex-row w-full'>
          {
            step == 1?
              <Step1 SkilsList={SkilsList} onBoardingData={onBoardingData} action={addRemoveActionSkills} />
            :step == 2?
            <Step2 GoalsList={GoalsList} onBoardingData={onBoardingData} action={addRemoveActionGoals} />
            :step == 3?
            <Step3 learningStylesList={learningStylesList} onBoardingData={onBoardingData} action={addRemoveActionLearningStyles} />
            :step == 4?
            <Step4 ageGroups={ageGroups} onBoardingData={onBoardingData} action={addRemoveActionData} />
            :null
          } 
        </div>
        <div className='flex flex-row justify-center items-center gap-3'>
          {
            step > 1 ?
              <Button onClick={()=> stepChange(-1)} type='button' size="sm" color='primary' variant='outline' className={formSubmit?'w-20 py-3':'w-32 px-14 py-3'} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'PREVIOUS'}</Button>
            :null
          }
          {
            step < 4?
              <Button onClick={()=> stepChange(1)} type='button' size="sm" color='primary' variant='solid' className={formSubmit?'w-20 py-3':'w-32 px-14 py-3'} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'NEXT'}</Button>
            :<Button onClick={()=> FinalStepSubmit()} type='button' size="sm" color='primary' variant='solid' className={formSubmit?'w-20 py-3':'w-32 px-14 py-3'} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'NEXT'}</Button>
          }

        </div>
        
      </div>
    </div>
  );
}

export default OnBoarding;