import React, { useState } from "react";
import rightArrow from "../../../assets/svgs/rightArrow.svg";
import { Drawer } from "../../../components/ui-components";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../components/ui-components";

const learningMode = [
  {
    mode: "Online",
    checked: true,
  },
  {
    mode: "Classroom",
    checked: false,
  },
  {
    mode: "One On One",
    checked: true,
  },
];

function LearningMode() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className="w-full flex flex-row justify-between items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-8"
        onClick={() => setOpen(true)}>
        <p className="text-base text-primary">Preferres Learning Mode</p>
        <div className="flex flex-row justify-center gap-2">
          <p className="text-base text-primary">Online & One on One</p>
          <img src={rightArrow} alt="" />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Preferred Learning Mode">
        <form className="flex flex-col h-full justify-between">
            <div className="flex flex-col gap-5 px-6 py-4">
                {learningMode.map((ele, index) => (
                    <div className="flex flex-row gap-4">
                    <div className="flex h-6 shrink-0 items-center" key={index}>
                        <div className="group grid size-4 grid-cols-1">
                        <input
                            defaultChecked={ele.checked}
                            id={ele.mode}
                            name={ele.mode}
                            type="checkbox"
                            aria-describedby="comments-description"
                            className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-black checked:bg-black indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        />
                        <svg
                            fill="none"
                            viewBox="0 0 14 14"
                            className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25">
                            <path
                            d="M3 8L6 11L11 3.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:checked]:opacity-100"
                            />
                            <path
                            d="M3 7H11"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                            />
                        </svg>
                        </div>
                    </div>
                    <div className="text-sm/6">
                        <label htmlFor="comments" className="text-base font-medium text-gray-900">
                        {ele.mode}
                        </label>
                        
                    </div>
                    </div>
                ))}
            </div>
            <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-48 text-sm m-auto my-6 rounded-xl">
            SAVE
          </Button>
        </form>
      </Drawer>
    </>
  );
}

export default LearningMode;
