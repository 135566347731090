import React from "react";

function CategoryVideo() {
  return (
    <div className="relative px-4 ">
      <div className="flex justify-between items-center">
        <span className="text-[13px] font-medium bg-gray-100 border border-gray-400 py-1 px-2 rounded-full absolute top-12 left-8">
          Design
        </span>
        <span className="text-[13px] font-medium bg-gray-500 text-white py-1 px-2 rounded-full absolute top-12 right-8">
          Popular
        </span>
      </div>
      <h2 className="text-base font-semibold mb-4">Most Popular Courses</h2>
      <div className="h-44 bg-gray-100 rounded-2xl"></div>
      <h2 className="mt-4 text-sm font-bold">
        How to becoming UX designer designer
      </h2>
      <p className="mt-2 text-xs font-semibold text-gray-600">
        Online | Classroom | One on One | 60hrs
      </p>

      <div className="flex flex-row items-center mt-2">
        <div className="w-8 h-8 bg-gray-4 rounded-full"></div>
        <div className="ml-2 flex flex-row gap-2">
          <p className="text-sm font-medium">Rohan Joshi</p>
          <p className="text-sm text-gray-500">
            <span className="font-semibold">4.8</span> (200)
          </p>
        </div>
      </div>

      <p className="mt-2 text-sm font-semibold">
        <span className="text-xs text-gray-500">Starts From</span> ₹ 9,999/-
      </p>
    </div>
  );
}

export default CategoryVideo;
