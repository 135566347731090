import React, { useState } from "react";
// import { Button, FloatingTextField } from "../../components/ui-components";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Drawer } from "../../components/ui-components";

function Support({ onOpenChange }) {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formState);
  };

  return (
    <>
      <div className={`flex flex-row gap-16 justify-center my-40  `}>
        <div className="bg-[#F7F7F7] max-w-md h-48 rounded-2xl flex flex-col justify-center px-10 gap-2">
          <p className="text-xl font-semibold text-[#282938]">
            Instructors Support
          </p>
          <p className="text-base text-[#282938]">
            Do you have a particular problem that needs to be solved quickly?
          </p>
          <button
            className="text-sm px-5 py-2 bg-black text-white rounded-3xl w-32"
            onClick={() => setOpen(true)}>
            Send an Email
          </button>
        </div>

        <div className="flex flex-col w-[38%] h-[654px] gap-4">
          <p className="text-xl font-medium">Learner Support</p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-between">
            <div className="pb-6 flex flex-col gap-4">
              <div className="flex flex-col">
                <label htmlFor="" className="text-base text-gray-500">
                  First Name*
                </label>
                <input
                  type="text"
                  className="bg-[#F7F7F7] h-14 rounded-lg border-none"
                  value={formState.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="" className="text-base text-gray-500">
                  Last Name*
                </label>
                <input
                  type="text"
                  className="bg-[#F7F7F7] h-14 rounded-lg border-none"
                  value={formState.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="" className="text-base text-gray-500">
                  Email*
                </label>
                <input
                  type="email"
                  className="bg-[#F7F7F7] h-14 rounded-lg border-none"
                  value={formState.email}
                  onChange={handleChange}
                />
              </div>

              <div className="">
                <label htmlFor="comment" className="text-base text-gray-500">
                  Your Message*
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  rows={4}
                  className="block w-full h-40 rounded-lg bg-[#F7F7F7] px-3 py-1.5 text-base text-gray-900 border-none  outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  defaultValue={""}
                  required
                  placeholder="Type..."
                  onChange={formState.message}
                />
              </div>
            </div>

            <button
              type="submit"
              className="self-end text-lg px-10 py-3 bg-[#000000] rounded-3xl text-white mt-4">
              Send
            </button>
          </form>
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Instructor Support">
        <form
          onSubmit={handleSubmit}
          className="flex max-w-md m-auto flex-col justify-between">
          <div className="pb-6 flex flex-col gap-4">
            <div className="flex flex-col">
              <label htmlFor="" className="text-base text-gray-500">
                First Name*
              </label>
              <input
                type="text"
                className="bg-[#F7F7F7] h-14 rounded-lg border-none"
                value={formState.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="text-base text-gray-500">
                Last Name*
              </label>
              <input
                type="text"
                className="bg-[#F7F7F7] h-14 rounded-lg border-none"
                value={formState.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className="text-base text-gray-500">
                Email*
              </label>
              <input
                type="email"
                className="bg-[#F7F7F7] h-14 rounded-lg border-none"
                value={formState.email}
                onChange={handleChange}
              />
            </div>

            <div className="">
              <label htmlFor="comment" className="text-base text-gray-500">
                Your Message*
              </label>
              <textarea
                id="comment"
                name="comment"
                rows={4}
                className="block w-full h-40 rounded-lg bg-[#F7F7F7] px-3 py-1.5 text-base text-gray-900 border-none  outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                defaultValue={""}
                required
                placeholder="Type..."
                onChange={formState.message}
              />
            </div>
          </div>

          <button
            type="submit"
            className="text-sm w-60 m-auto px-10 py-3 bg-[#000000] rounded-3xl text-white mt-4">
            Send
          </button>
        </form>
      </Drawer>
    </>
  );
}

export default Support;
