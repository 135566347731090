import React from "react";
import { EditPencilTwo } from "../../../components/icons";
import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Drawer } from "../../../components/ui-components";

function Language() {
  const [open, setOpen] = useState(false);

  const notificationMethods = [
    { id: "english", title: "English" },
    { id: "spanish", title: "Spanish" },
    { id: "arabic", title: "Arabic" },
    { id: "tamil", title: "Tamil" },
    { id: "hindi", title: "Hindi" },
  ];
  return (
    <>
      <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">
          Preferred Language
        </h3>
        <button
          className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-5 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <div className="flex flex-row mx-6 mb-4 gap-6">
          <button className="border border-gray-200 px-3 py-1 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            English
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300  text-lg transform transition duration-300 hover:scale-110">
            Hindi
          </button>
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Preferred Language">
        <fieldset>
          <div className="relative w-full">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id={"head-search"}
              type={"text"}
              placeholder="Search language"
              // onClick={() => setOpen(true)}
              // readOnly
              className={
                "bg-white border text-sm border-gray-400 rounded-4xl w-full max-w-md h-10 pl-8 text-input-text placeholder:text-input-placeholder"
              }
            />
          </div>
          <div className="mt-6 space-y-6">
            {notificationMethods.map((notificationMethod) => (
              <div key={notificationMethod.id} className="flex items-center">
                <input
                  id={notificationMethod.id}
                  name="notification-method"
                  type="radio"
                  className="relative size-4 appearance-none rounded-lg border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-lg before:bg-white checked:border-indigo-600 checked:bg-indigo-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                />
                <label
                  htmlFor={notificationMethod.id}
                  className="ml-3 block text-lg font-medium text-gray-900">
                  {notificationMethod.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </Drawer>
    </>
  );
}

export default Language;
