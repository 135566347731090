import React from "react";
import { EditPencilTwo } from "../../../components/icons";
import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Drawer } from "../../../components/ui-components";

function Bio() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Bio</h3>
        <button
          className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-5 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <p className="px-4 mb-6 text-lg text-gray-600">
          I&#39;m a recent graduate with a passion for data. I&#39;m eager to
          learn Full Stack Web Development techniques and build a strong
          foundation in this exciting field. I&#39;m excited to explore the
          courses offered on SimpliTrain and gain the necessary skills to
          kickstart my Developer career.
        </p>
      </div>

      <Drawer open={open} onClose={setOpen} title="Edit Bio">
        <div className="flex flex-col justify-between h-full">
          <textarea
            name=""
            className="text-lg rounded-md bg-gray-100 border-none shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-44 overflow-y-auto"></textarea>
          <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-52 my-4 m-auto text-2xl">
            SAVE
          </Button>
        </div>
      </Drawer>
    </>
  );
}

export default Bio;
