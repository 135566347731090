import { Fragment, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


const offers = [
  { name: 'Download the app', description: 'Get an exclusive $5 off code', href: '#' },
  { name: "Return when you're ready", description: '60 days of free returns', href: '#' },
  { name: 'Sign up for our newsletter', description: '15% off your first order', href: '#' },
]


export async function homeLoader(){
  return {
    date: new Date().toISOString(),
  };
}


export default function Home() {
 
  let data = useLoaderData();
  return (
    <>
      <div className="relative isolate pt-14">
       
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                Data to enrich your online business
              </h1>
              <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
                fugiat veniam occaecat.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                  <ul>
                    <li><a href="/faq" className="text-sm/6 font-semibold text-gray-900">Faqs</a></li>
                    <li><a href="/features" className="text-sm/6 font-semibold text-gray-900">features</a></li>
                    <li><a href="/aboutus" className="text-sm/6 font-semibold text-gray-900">aboutus</a></li>
                    <li><a href="/documents" className="text-sm/6 font-semibold text-gray-900">Policy</a></li>
                    {/* <li><a href="/documents" className="text-sm/6 font-semibold text-gray-900">Policy</a></li> */}
                    
                    <li><a href="/dashboard" className="text-sm/6 font-semibold text-gray-900">dashboard</a></li>


                    
                  </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}