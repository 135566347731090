import React from "react";

const Icon = (props) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00089 3C10.4331 3 11.8066 3.50571 12.8193 4.40589C13.832 5.30606 14.4009 6.52696 14.4009 7.8C14.4009 9.76906 14.846 11.2436 15.4232 12.3279C16.1606 13.7133 16.5293 14.406 16.5088 14.5642C16.4849 14.7489 16.4544 14.7997 16.3026 14.9075C16.1725 15 15.5254 15 14.2311 15H3.77066C2.47638 15 1.82925 15 1.69916 14.9075C1.54741 14.7997 1.51692 14.7489 1.493 14.5642C1.47249 14.406 1.8412 13.7133 2.57863 12.3279C3.1558 11.2436 3.60089 9.76906 3.60089 7.8C3.60089 6.52696 4.16982 5.30606 5.18251 4.40589C6.19521 3.50571 7.56872 3 9.00089 3ZM9.00089 3V1M6.35489 18C7.06114 18.6233 7.98885 19.0016 9.00489 19.0016C10.0209 19.0016 10.9486 18.6233 11.6549 18" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default Icon;
