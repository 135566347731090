import React from 'react'

function ExpertSupport() {
  return (
    <>
        <div className="bg-[#E9E9E9] w-full h-[471px] flex flex-col items-center gap-10 justify-center">
            <h1 className='text-5xl font-medium'>Get in Touch for Expert Support!</h1>
            <div className='flex flex-row gap-32'>
                <div className='w-96 h-40  flex flex-col items-center text-center px-4 gap-3'>
                    <div className='w-8 h-8 bg-[#D9D9D9] rounded-full'></div>
                    <h2 className='text-2xl text-[#000000B2] font-medium'>Learners</h2>
                    <p className='text-base text-[#000000B2]'>Please reach out to us at <b>learnersupport@simplitrain.com</b>, and our team will respond within 24 hours to assist you.</p>
                </div>
                <div className='w-96 h-40 flex flex-col items-center text-center px-0 gap-3'>
                    <div className='w-8 h-8 bg-[#D9D9D9] rounded-full'></div>
                    <h2 className='text-2xl text-[#000000B2] font-medium'>Instructors</h2>
                    <p className='text-base text-[#000000B2]'>Please reach out to us at <b>learnersupport@simplitrain.com</b>, and our team will respond within 24 hours to assist you.</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ExpertSupport
