import React from "react";

const Icon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0284 2.26592L6.11844 8.17592C5.89344 8.40092 5.66844 8.84342 5.62344 9.16592L5.30094 11.4234C5.18094 12.2409 5.75844 12.8109 6.57594 12.6984L8.83344 12.3759C9.14844 12.3309 9.59094 12.1059 9.82344 11.8809L15.7334 5.97092C16.7534 4.95092 17.2334 3.76592 15.7334 2.26592C14.2334 0.765922 13.0484 1.24592 12.0284 2.26592Z" stroke="#292D32" strokeWidth="2" strokeM="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.1836 3.10938C11.6861 4.90187 13.0886 6.30437 14.8886 6.81437" stroke="#292D32" strokeWidth="2" strokeM="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
