import React from "react";

const Icon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27 27C25.3333 27 23.9167 26.4167 22.75 25.25C21.5833 24.0833 21 22.6667 21 21C21 19.3333 21.5833 17.9167 22.75 16.75C23.9167 15.5833 25.3333 15 27 15C28.6667 15 30.0833 15.5833 31.25 16.75C32.4167 17.9167 33 19.3333 33 21C33 22.6667 32.4167 24.0833 31.25 25.25C30.0833 26.4167 28.6667 27 27 27ZM11 34C10.175 34 9.46883 33.7062 8.8815 33.1185C8.29383 32.5312 8 31.825 8 31V11C8 10.175 8.29383 9.46867 8.8815 8.881C9.46883 8.29367 10.175 8 11 8H43C43.825 8 44.5313 8.29367 45.119 8.881C45.7063 9.46867 46 10.175 46 11V31C46 31.825 45.7063 32.5312 45.119 33.1185C44.5313 33.7062 43.825 34 43 34H11ZM16 31H38C38 29.6 38.4833 28.4167 39.45 27.45C40.4167 26.4833 41.6 26 43 26V16C41.6 16 40.4167 15.5167 39.45 14.55C38.4833 13.5833 38 12.4 38 11H16C16 12.4 15.5167 13.5833 14.55 14.55C13.5833 15.5167 12.4 16 11 16V26C12.4 26 13.5833 26.4833 14.55 27.45C15.5167 28.4167 16 29.6 16 31ZM38.5 40H5C4.175 40 3.46883 39.7062 2.8815 39.1185C2.29383 38.5312 2 37.825 2 37V15.5C2 15.075 2.14467 14.7187 2.434 14.431C2.723 14.1437 3.08133 14 3.509 14C3.93633 14 4.29167 14.1437 4.575 14.431C4.85833 14.7187 5 15.075 5 15.5V37H38.5C38.925 37 39.2813 37.1447 39.569 37.434C39.8563 37.723 40 38.0813 40 38.509C40 38.9363 39.8563 39.2917 39.569 39.575C39.2813 39.8583 38.925 40 38.5 40Z" fill={props.color} />
    </svg>

  );
};

export default Icon;






