import React from "react";
import WorkExprienceCard from "./WorkExprienceCard";
import { Drawer, FloatingTextField } from "../../../components/ui-components";
import { Dialog, DialogPanel, DialogTitle, Field } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/ui-components";
import { useState } from "react";

function WorkExperience() {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    title: "",
    company: "",
    stateDate: "",
    endDate: "",
    employmentType: "",
    industry: "",
    location: "",
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = (e) => {
    e.preventDefault();
    console.log(formState);
  };
  return (
    <>
      <div className="relative">
        <button
          className="border bg-[#292D32] text-white text-base border-gray-300 px-4 py-2 rounded-full absolute right-6 top-0 w-22 transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          ADD
        </button>
        <h3 className="mb-8 font-bold text-lg">Add Work Exprience</h3>

        <div className="bg-white px-6 py-6 rounded-xl">
          <WorkExprienceCard />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Add Work Exprience">
        <form
          onSubmit={onFinish}
          method="POST"
          className="flex flex-col justify-between h-full w-96 m-auto mt-6">
          {/* <FloatingLabel variant="filled" className='h-16 mb-1 pl-4 pr-4 w-full bg-input-background border-0 text-input-text placeholder-transparent rounded-xl' label="Email ID / Phone Number" /> */}

          <div className="flex flex-col justify-center">
            <FloatingTextField
              label="TITLE"
              type="text"
              onChange={handleChange}
              value={formState.title}
              name={"title"}
              id={"title"}
              // placeholder=""
              // className="w-full"
            />
            <FloatingTextField
              label="COMPANY NAME"
              type="text"
              value={formState.company}
              onChange={handleChange}
              name={"company"}
              id={"company"}
              // placeholder="demo@gmail.com"
            />
            <FloatingTextField
              label="START DATE"
              type="date"
              value={formState.startDate}
              onChange={handleChange}
              name={"startDate"}
              id={"startDate"}
            />
            <FloatingTextField
              label="END DATE"
              type="date"
              value={formState.endDate}
              onChange={handleChange}
              name={"endDate"}
              id={"endDate"}
            />
            <FloatingTextField
              label="EMPLOYMENT TYPE"
              type="text"
              value={formState.employmentType}
              onChange={handleChange}
              name={"employmentType}"}
              id={"employmentType}"}
              // placeholder="demo@gmail.com"
            />
            <div className="rounded-md px-3 pb-1.5 pt-2.5  -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600 w-full bg-[#F7F7F7] mb-4">
              {/* <label htmlFor="gender" className="absolute top-1">Gender</label> */}
              <select
                name="industry"
                className="border-none w-full bg-[#F7F7F7]"
                value={formState.industry}
                onChange={handleChange}>
                <option value="#">Industry</option>
                <option value="software">Software Development</option>
                <option value="sales">Sales</option>
                <option value="automobile">AutoMobile</option>
              </select>
            </div>
            <FloatingTextField
              label="LOCATION"
              type="text"
              value={formState.location}
              onChange={handleChange}
              name={"location"}
              id={"location"}
              // placeholder="demo@gmail.com"
            />
          </div>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-52 m-auto my-6">
              ADD
            </Button>
          
        </form>
      </Drawer>
    </>
  );
}

export default WorkExperience;
