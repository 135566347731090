import React from "react";
import { EditPencilTwo } from "../../../components/icons";

function EducationCard() {
  const educationData = [
    {
      img: "",
      university: "IASE University",
      degree: "Diploma In Computer Science",
      year: "2015-2018",
    },
    {
      img: "",
      university: "IGNOU University",
      degree: "Bachlor In Computer Science",
      year: "2015-2018",
    },
  ];
  return (
    <>
      <div className="flex flex-col gap-4">
        {educationData.map((item, index) => (
          <div
            className="border border-gray-200 rounded-xl px-4 py-4 flex flex-row gap-4 relative"
            key={index}>
            <button className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110">
              <p className="text-base ml-2">Edit</p>
              <EditPencilTwo color="black" />
            </button>
            <div className="bg-gray-100 rounded-xl w-24 h-24"></div>
            <div className="flex flex-col justify-center">
              <h4 className="text-xl font-medium mb-2">
                {item.university}
              </h4>
              <p className="text-gray-500 font-medium text-sm">{item.degree}</p>
              <p className="text-gray-500 text-sm">{item.year}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default EducationCard;
