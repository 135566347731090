import { Fragment, useState } from 'react'
import { Link, useLoaderData } from "react-router-dom";
import FilterSideBar from './filterSidebar';
import { Button, Container } from '../../components/ui-components';
import CourseCard from './courseCard';
import { Filter } from '../../components/icons';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';


const courses = [
  { name: 'Download the app', description: 'Get an exclusive $5 off code', href: '#' },
  { name: "Return when you're ready", description: '60 days of free returns', href: '#' },
  { name: 'Sign up for our newsletter', description: '15% off your first order', href: '#' },
  { name: 'Sign up for our newsletter', description: '15% off your first order', href: '#' },
  { name: 'Sign up for our newsletter', description: '15% off your first order', href: '#' },
  { name: 'Sign up for our newsletter', description: '15% off your first order', href: '#' }
]

const popularOptions= [
  {
    label:'Most Popular',
    key:'Popular'
  },
  {
    label:'Newly Added',
    key:'Newly Added'
  },
  {
    label:'High to Low Reviews',
    key:'reviews_high'
  },
  {
    label:'Low to High Reviews',
    key:'reviews_low'
  },
  {
    label:'Price: Low to High',
    key:'price_low'
  },
  {
    label:'Price: High to Low',
    key:'price_high'
  },
  {
    label:'Shortest Duration',
    key:'shortest_duration'
  },
  {
    label:'Longest Duration',
    key:'longest_duration'
  }
];

export async function coursesLoader(){
  return {
    date: new Date().toISOString(),
  };
}


export default function Courses() {
  const [sorting, setSorting] = useState('Popular');
  const [filterSidebar, setFilterSidebar] = useState(false);
  
  let data = useLoaderData();

  const applySorting = (action) => {
    setSorting(action.key);
  }

  return (
    <div className="bg-white flex flex-row justify-start items-stretch">
      <div className={`flex h-full transition-all duration-300 ease-in-out w-96 ${filterSidebar?'ml-0':'-ml-96'}`}>
          <FilterSideBar onClose={setFilterSidebar} />
      </div>
      <div className='flex flex-col w-full'>
        <div className='border-b border-b-gray-5 py-6'>
          <div className='flex flex-row justify-center items-center w-full p-4'>
            <h3 className='text-text text-3xl text-center font-medium'>{'Guitar for Beginner'}</h3>
          </div>
          <Container>
            <div className='flex flex-row justify-between w-full items-center'>
              <div><button onClick={()=> setFilterSidebar(!filterSidebar) } className={'bg-white border border-gray-5 rounded-full px-4 py-2 text-sm inline-flex gap-2 justify-center font-semibold text-text items-center'}><Filter /> <span>Filter</span></button></div>
              <div></div>
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="inline-flex justify-center items-center border border-gray-5 w-full gap-2 rounded-full bg-white px-4 py-2 text-sm font-semibold text-text">
                      {sorting}
                      <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-gray-400" />
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      {
                        popularOptions.map((item, index)=>(
                          <MenuItem key={index} onClick={()=> applySorting(item)}>
                            <a
                              className="flex flex-row justify-between items-center px-4 py-2 text-sm text-text data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none font-medium"
                            >
                              <span>{item.label}</span>
                              {
                                sorting == item.key?
                                  <CheckIcon className='size-4' />
                                :null
                              } 
                            </a>
                            
                          </MenuItem>
                        ))
                      }
                     
                     
                    </div>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </Container>
        </div>
        <div className='py-6'>
          <Container>
            <div className='grid grid-cols-3 gap-10 py-10'>
              {
                courses.map((course, index)=>(
                  <Link to={'/courses/courseid'} key={index+1}>
                    <CourseCard type={index} />
                  </Link>
                ))
              }
            </div>
          </Container>
        </div>
         
      </div>  
    </div>
  );
}