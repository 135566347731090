import React from "react";
import { EditPencilTwo } from "../../../components/icons";
import linkedin from "../../../assets/svgs/linkedin.svg";
import twitter from "../../../assets/svgs/twitter.svg";
import colorGoogle from "../../../assets/svgs/colorGoogle.svg";
import pinkball from "../../../assets/svgs/pinkball.svg";
import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Drawer, FloatingTextField } from "../../../components/ui-components";
import { Button } from "../../../components/ui-components";

function SocialMedia() {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    linkedin: "",
    instagram: "",
    facebook: "",
    twitter: "",
    youtube: "",
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formState);
  };
  return (
    <>
      <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Social Media</h3>
        <button
          className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <div className="flex flex-row mx-6 mb-6 gap-6">
          <img src={linkedin} alt="linkedin logo" />
          <img src={twitter} alt="twitter logo" />
          <img src={colorGoogle} alt="Google logo" />
          <img src={pinkball} alt="PinkBall logo" />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Edit Social Media Link">
          <form
            onSubmit={handleSubmit}
            className="w-96 flex flex-col justify-between m-auto mt-4 h-full">
              <div className="flex flex-col justify-center">
                <FloatingTextField
                  label="Linkedin Link"
                  type="text"
                  value={formState.linkedin}
                  onChange={handleChange}
                  name={"linkedin"}
                  id={"linkedin"}
                  // placeholder="demo@gmail.com"
                />
                <FloatingTextField
                  label="Instagram Link"
                  type="text"
                  value={formState.instagram}
                  onChange={handleChange}
                  name={"instagram"}
                  id={"instagram"}
                  // placeholder="demo@gmail.com"
                />
                <FloatingTextField
                  label="Facebook Link"
                  type="text"
                  value={formState.facebook}
                  onChange={handleChange}
                  name={"facebook"}
                  id={"facebook"}
                  // placeholder="demo@gmail.com"
                />
                <FloatingTextField
                  label="X Link"
                  type="text"
                  value={formState.twitter}
                  onChange={handleChange}
                  name={"twitter"}
                  id={"twitter"}
                  // placeholder="demo@gmail.com"
                />
                <FloatingTextField
                  label="Youtube Link"
                  type="text"
                  value={formState.youtube}
                  onChange={handleChange}
                  name={"youtube"}
                  id={"youtube"}
                  // placeholder="demo@gmail.com"
                />
              </div>

              <Button
                type="submit"
                color="primary"
                variant="solid"
                className="w-52 m-auto my-6">
                SAVE
              </Button>
          </form>
      </Drawer>

      
    </>
  );
}

export default SocialMedia;
