
import React, { useEffect, useState } from 'react';

export async function myCoursesLoader(){
    return {
      date: new Date().toISOString(),
    };
}


const MyCourses = () => {

    return (
    <div className="bg-gray-50 flex flex-row items-start justify-start">
        <h1>My Courses</h1>
    </div>
  );
};

export default MyCourses;
