import React from "react";

const Icon = (props) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="18" height="18">
        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
        <path fill="none" d="M0 0h48v48H0z"></path>
    </svg>
    // <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M9 7.36328V10.8487H13.8436C13.6309 11.9696 12.9927 12.9188 12.0354 13.5569L14.9563 15.8233C16.6581 14.2525 17.64 11.9452 17.64 9.20428C17.64 8.56611 17.5827 7.95242 17.4763 7.36338L9 7.36328Z" fill="black" fillOpacity="0.7"/>
    //     <path d="M3.95547 10.7129L3.2967 11.2172L0.964844 13.0335C2.44575 15.9708 5.48098 17.9999 8.99913 17.9999C11.4291 17.9999 13.4663 17.1981 14.9554 15.8235L12.0345 13.5572C11.2327 14.0972 10.21 14.4245 8.99913 14.4245C6.65915 14.4245 4.67102 12.8454 3.95915 10.7181L3.95547 10.7129Z" fill="black" fillOpacity="0.7"/>
    //     <path d="M0.965384 4.9668C0.351781 6.17766 0 7.54404 0 9.00038C0 10.4567 0.351781 11.8231 0.965384 13.034C0.965384 13.0421 3.95998 10.7103 3.95998 10.7103C3.77998 10.1703 3.67359 9.59764 3.67359 9.00028C3.67359 8.40293 3.77998 7.83024 3.95998 7.29025L0.965384 4.9668Z" fill="black" fillOpacity="0.7"/>
    //     <path d="M8.99932 3.58363C10.3248 3.58363 11.503 4.0418 12.4439 4.92545L15.0211 2.34821C13.4584 0.891874 11.4294 0 8.99932 0C5.48116 0 2.44575 2.02091 0.964844 4.96637L3.95935 7.29001C4.67112 5.16271 6.65933 3.58363 8.99932 3.58363Z" fill="black" fillOpacity="0.7"/>
    // </svg>
  );
};

export default Icon;
