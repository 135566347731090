import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";
import GoogleButton from '../../components/shared-components/GoogleButton';
import AppleButton from '../../components/shared-components/AppleButton';
import AuthFooter from '../../components/shared-components/AuthFooter';
import { Button, Divider, Spinner } from '../../components/ui-components';
import clsx from 'clsx'
import { XMarkIcon } from '@heroicons/react/24/outline';

const tagCss = 'text-base font-normal px-4 py-[0.3rem] rounded-full border border-text';

const Step1 = ({ onBoardingData, action, SkilsList }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const checkAdded = (val) => {
    let skills = [...onBoardingData.skills];
    if(skills.includes(val)){
      return 'bg-primary text-white';
    }else{
      return 'bg-white text-text';
    }
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='flex flex-row flex-wrap items-center justify-center w-full p-5 gap-3'>
    {
      SkilsList.map((skil, index)=>(
        <span key={index} onClick={()=>{ action(skil); }} className={clsx(tagCss, checkAdded(skil))}>{skil}</span>
      ))
    }
    <span onClick={toggleDrawer} className={tagCss}>Other?</span>
   </div>
  );
}

export default Step1;