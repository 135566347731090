// import Cookies from 'js-cookie';

const getItem = (key) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : '';

  try {
    return data?JSON.parse(window.atob(data)):null;
  } catch (err) {
    return null;
  }
};

// const getCookies = (key:string) => {
//   const user = Cookies.get('cinemaWallaUser')?JSON.parse(window.atob(Cookies.get('cinemaWallaUser'))):{'token':''};
//   try {
//     return user[key];
//   } catch (err) {
//     return null;
//   }
// };

const setItem = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  return localStorage.setItem(key, stringify);
};

const removeItem = (key) => {
  localStorage.removeItem(key);
};

export { getItem, setItem, removeItem };
