import React, { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
// import { Button, Divider, Form, FormProps, Input, Select, Space } from 'antd';
import AuthFooter from '../../components/shared-components/AuthFooter';
import { BackArrow, EditPencil } from '../../components/icons';
import { Button, PincodeField, Spinner } from '../../components/ui-components';

export async function verificationLoader() {
  // await sleep();
  return {
    date: new Date().toISOString(),
  };
}
  
const Verification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [newUser, setNewUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formData, setFormData] = useState({
    pincode: '',
  });
  
  let data = useLoaderData();
  // const [signform] = Form.useForm();

  
  const validate = () => {
    const newErrors = {};
    // Pincode validation
    console.log(formData.pincode);
    const phoneFormat = /^[0-9]{6}$/;
    if (!formData.pincode) {
      // console.log('sasasasasasadsgedfhfhfdgg', formData.pincode);
      newErrors.pincode = 'Please enter the verification code.';
    }else if (formData.pincode.length < 6 || formData.pincode.length > 6) {
      newErrors.pincode = 'The code must be 6 digits.';
    }else if (!phoneFormat.test(formData.pincode)) {
      newErrors.pincode = 'Please enter only numbers.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const onFinish = async (e) => {
    e.preventDefault();
    setFormSubmit(true);
    // console.log('sasasassasa');
    setTimeout(() => {
      if (validate()) {
          const newErrors = {};
          // alert('Form submitted successfully!');
          console.log(formData);
          if(formData.pincode == '123456'){
            if(newUser){
              navigate('/auth/setpassword');
            }else{
              navigate('/');
            }
          }else{
            newErrors.pincode = 'The code you entered is incorrect. Please try again.';
            setErrors(newErrors);
          }

          // if(inputType == 'text'){

          // }else{
          //   navigate('/auth/verification', {state:{username:formData.username,type:inputType}});
          // }
          setFormSubmit(false);
      }else{
        setFormSubmit(false);
      }
    }, 2000);
    // console.log('values', values);
    // 
    
    // values['type'] = fieldStatus?'phone':'email';
    // let reponse = await dispatch(checkUser(values));
    // console.log("reponse", reponse)
    
    // if(reponse){
    //   if(reponse.type === 'AUTH_SUCCESS'){
    //     if(reponse.data.newUser){
    //       navigate('/auth/verification',{state:values});
    //     }else{
    //       navigate('/auth/password',{state:values});
    //     }
    //   }else{
        
    //   }
    // }else{

    // }    
  };

  const handleChange = (value) => {
    // console.log('e.target', );
    // const value = e.target.value;
    console.log(value);
    setFormData({ ...formData, pincode: value })
    // e.target.autofocus = true;
  };

  useEffect(()=>{
    if(location && location.state){
      // console.log('location.state', location.state);
      setUsername(location.state.username);
      setNewUser(location.state.new_user);
    }else{
      navigate('/auth/signin');
    }
  },[]);


  return (
    <div className='flex flex-col justify-start items-start gap-4'>
      <h3 className='text-2xl text-text text-left w-full font-medium'>{'Verification Code'}</h3>
      <p className="text-base text-text text-left w-full">We've sent a six-digit code for verification to your following mobile number. Kindly enter the code below</p>
      <p className='flex flex-row justify-start items-center gap-2'>{username} 
        <a href="/auth/signin" color='text' className={'p-0'}>{<EditPencil />}</a>
      </p>
      <form  onSubmit={onFinish} method="POST" className="w-full">
        {/* <FloatingTextField label="Password" type={'password'} name={"password"} id={"password"} placeholder="Password" error={errors.password} /> */}
        <PincodeField label="" type={'tel'} name={"pincode"} id={"pincode"} placeholder="Pincode" onChange={handleChange} error={errors.pincode} />
        <div className='flex flex-row items-center justify-between w-full py-4 m-0 mb-3'>
          <a href="/auth/forgot" color='text' className={'font-medium p-0 underline'}>{'Resend'}</a>
          <span>0:0</span>
        </div>
        <Button type='submit' color='primary' variant='solid' className={formSubmit?'w-20':'w-full'} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'Confirm'}</Button>
      </form>
    </div>
  );
}

export default Verification;