import React from "react";

const AboutCourse = () => {
  return (
    <section className="p-6 bg-gray-50">
      <h3 className="text-xl font-bold mb-4">About Course</h3>
      <p className="text-gray-700">
        This course provides a comprehensive guide to becoming a UX designer...
      </p>
    </section>
  );
};

export default AboutCourse;
