import React, { useEffect, useState } from "react";
// import { EditPencil } from "../../../components/icons";
import { EditPencilTwo } from "../../../components/icons";
// import userCircle from "../../../assets/svgs/userCircle.svg";
import PersonalInfo from "./PersonalInfo";
import ContactInfo from "./ContactInfo";
import Bio from "./Bio";
import Language from "./Language";
import Topics from "./Topics";
import SocialMedia from "./SocialMedia";
import ProfileCard from "./ProfileCard";
import Education from "./Education";
import WorkExperience from "./WorkExprience";
import { Container } from "../../../components/ui-components";

export async function myProfileLoader() {
  return {
    date: new Date().toISOString(),
  };
}

const MyProfile = () => {
  
  return (
    <>
      <div className="w-full bg-gray-6 relative">
        <div className="bg-[#D9D9D9] absolute h-60 w-full top-0 left-0 z-0">
          <button className="border border-gray-400 px-2 py-2 rounded-full absolute right-8 top-4 w-14 flex justify-center items-center">
            <EditPencilTwo color="black" />
          </button>
        </div>
        {/* Profile Section */}
        <div className="w-full mt-36 relative z-10">
          <Container>
            <div className="flex flex-row justify-start items-start gap-6">
              <div className="flex w-64 pt-12">
                {/* Profile Card */}
                <ProfileCard />
              </div>
              <div className="flex flex-col w-full mb-8">
                <h3 className="font-semibold h-12 text-lg">Profile</h3>
                <div className="profile-info-card bg-white px-4 py-4 rounded-xl">
                  {/* personal Information */}
                  <PersonalInfo/>

                  {/* Contact Information */}
                  <ContactInfo />

                  {/* Bio */}
                  <Bio />

                  {/* Preferred Language */}
                  <Language />

                  {/* Topics */}
                  <Topics />

                  {/* Social Media */}
                  <SocialMedia />
                </div>
                {/* Education Section */}
                <div className="my-5">
                  <Education />
                </div>
                {/* Work Experience Section */}
                <div className="my-5">
                  <WorkExperience />
                </div>
              </div>
            </div>
          </Container>        
        </div>
      </div>
    </>
  );
};

export default MyProfile;
