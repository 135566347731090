import axios from 'axios';
import { getItem } from '../utils/localStorageControl';
// import Cookies from 'js-cookie';


// for live
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// for local
// const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_LOCAL;

// const authHeader = () => ({
//   'x-access-token': `Simplitrain ${getItem('access_token')}`,
// });

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    // 'x-access-token': `Simplitrain ${getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: {  },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...optionalHeader },
    });
  }

  static patch(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: data,
      headers: { ...optionalHeader  },
    });
  }
  
  static delete(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: data,
      headers: { ...optionalHeader  },
    });
  }

  static put(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: data,
      headers: { ...optionalHeader },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((configparams) => {
  // do something before executing the request
  // For example tag along the Simplitrain access token to request header or set a cookie
  const requestConfig = configparams;
  const { headers } = configparams;
  requestConfig.headers = { ...headers, 'x-access-token': `Simplitrain ${getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    // Token is not valid
    console.log('API   responseresponse', response);
    
    if(response.status == 200 && response.data.success){
      return response;
    }else{
      if(response.data.message == 'Token is not valid'){
        // config.storageTypes.localStorage.map((storage)=>{
        //   localStorage.removeItem(storage);
        // });
        // config.storageTypes.cookies.map((storage)=>{
        //   Cookies.remove(storage);
        // });
        window.location.reload();
      }else{
        return response;
      }
    }
  },
  // response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
        return {data:{success:false, message:{title:'Something went wrong!', message:'Please try after some time'}}};
      }if (response.status === 404) {
        return {data:{success:false, message:{title:'Page Not Found', message:'Pleae check your url'}}};
      } else {
        return originalRequest;
      }
    }else{
      console.log('error', error);
    }
    return Promise.reject(error);
  },
);
export { DataService };
