import { Fragment, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import InnerHeroSection from '../../components/shared-components/InnerHeroSection';
import { Container } from '../../components/ui-components';
import InstructorBenefits from './InstructorBenefits';
import FaqSection from './FaqSection';
import HowitWorks from './HowitWorks';
import TeachingGetStarted from './TeachingGetStarted';
import SuccessStories from './SuccessStories';
import ToolsHelpYouSucceed from './ToolsHelpYouSucceed';


export async function instructorLoader(){
  return {
    date: new Date().toISOString(),
  };
}


export default function Instructor() {
 
  let data = useLoaderData();
  return (
    <>
      {/* Hero Section */}
      <section className="bg-gray-100 py-20">
        <Container>
          <InnerHeroSection preHeading={'Instructor'} heading={'Instructor'} textInfo={'Join our global community of instructors and make an impact through live, interactive courses.'} buttonText={'Become an instructor'} buttonLink={'/become_instructor'} />
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <InstructorBenefits />
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <HowitWorks />
        </Container>
      </section>
      <section className="bg-gray-6 py-16">
        {/* <Container> */}
          <ToolsHelpYouSucceed />
        {/* </Container> */}
      </section> 
      <section className="py-16">
        <Container>
          <SuccessStories />
        </Container>
      </section>      
      <section className="py-16">
        <Container>
          <FaqSection />
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <TeachingGetStarted />
        </Container>
      </section>
    </>
  );
}