import React, { useState } from "react";
import { EditPencilTwo } from "../../../components/icons";
import { Container } from "../../../components/ui-components";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, FloatingTextField } from "../../../components/ui-components";
import { DropDownField, Drawer } from "../../../components/ui-components";

function PersonalInfo() {
  // const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  // const [formSubmit, setFormSubmit] = useState(false);
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    age: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
  });

  const handleChange = (e) => {
    // let {name,value} = e.target;
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = async (e) => {
    e.preventDefault();
    console.log(formState);
  };

  return (
    <>
      <div className="border border-gray-200 rounded-xl relative mb-4">
        <button
          className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          <p className=" text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>

        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">
          Personal Information
        </h3>
        <div className="flex flex-row border-b border-gray-200 px-6 pb-3">
          <div className="w-1/3 flex items-center">
            <p className="text-gray-600 text-xs">FIRST NAME</p>
          </div>
          <p className="font-semibold text-sm">Rohan</p>
        </div>

        <div className="flex flex-row border-b border-gray-200 px-6 py-3">
          <div className="w-1/3">
            <p className="text-gray-600 text-xs">LAST NAME</p>
          </div>
          <p className="font-semibold text-sm">Rakesh</p>
        </div>

        <div className="flex flex-row  border-b border-gray-200 px-6 py-3">
          <div className="w-1/3">
            <p className="text-gray-600 text-xs">AGE</p>
          </div>
          <p className="font-semibold text-sm">105</p>
        </div>

        <div className="flex flex-row border-b border-gray-200 px-6 py-3">
          <div className="w-1/3">
            <p className="text-gray-600 text-xs">GENDER</p>
          </div>
          <p className="font-semibold text-sm">Male</p>
        </div>

        <div className="flex flex-row border-gray-200 px-6 py-3">
          <div className="w-1/3">
            <p className="text-gray-600 text-xs">ADDRESS</p>
          </div>
          <p className="font-semibold text-sm">
            Mansarovar Park, Shahdara, Delhi 110032
          </p>
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Edit Personal Information">
        <form
          onSubmit={onFinish}
          method="POST"
          className="flex flex-col justify-center w-96 m-auto">
          {/* <FloatingLabel variant="filled" className='h-16 mb-1 pl-4 pr-4 w-full bg-input-background border-0 text-input-text placeholder-transparent rounded-xl' label="Email ID / Phone Number" /> */}
          <FloatingTextField
            label="FIRST NAME"
            type="text"
            onChange={handleChange}
            value={formState.firstName}
            name={"firstName"}
            id={"firstName"}
            placeholder="Rohan"
            // className="w-full"
          />
          <FloatingTextField
            label="LAST NAME"
            type="text"
            value={formState.lastName}
            onChange={handleChange}
            name={"lastName"}
            id={"lastName"}
            placeholder="demo@gmail.com"
          />
          <FloatingTextField
            label="EMAIL ID"
            type="email"
            value={formState.email}
            onChange={handleChange}
            name={"email"}
            id={"email"}
            placeholder="demo@gmail.com"
          />

          <DropDownField
            options={[
              { key: 1, value: "Male" },
              { key: 2, value: "Female" },
              { key: 3, value: "Other" },
            ]}
            label="Gender"
            name={"gender"}
            id={"gender"}
            placeholder="gender"
            error={""}
            value={formState.gender}
          />
          <FloatingTextField
            label="Age"
            type="number"
            value={formState.age}
            onChange={handleChange}
            name={"age"}
            id={"age"}
            placeholder="demo@gmail.com"
          />
          <FloatingTextField
            label="Address line 1"
            type="text"
            value={formState.address1}
            onChange={handleChange}
            name={"address1"}
            id={"address1"}
            placeholder="demo@gmail.com"
          />
          <FloatingTextField
            label="LAST NAME"
            type="text"
            value={formState.address2}
            onChange={handleChange}
            name={"address2"}
            id={"address2"}
            placeholder="demo@gmail.com"
          />

          <div className="grid grid-cols-2 gap-3">
            <FloatingTextField
              label="City"
              type="text"
              value={formState.city}
              onChange={handleChange}
              name={"city"}
              id={"city"}
              placeholder="demo@gmail.com"
            />
            <DropDownField
              options={[
                { key: 1, value: "Delhi" },
                { key: 2, value: "Rajesthan" },
                { key: 3, value: "Punjab" },
              ]}
              label="State"
              name={"state"}
              id={"state"}
              placeholder="state"
              error={""}
              value={formState.state}
            />
            <FloatingTextField
              label="Pincode"
              type="number"
              value={formState.pincode}
              onChange={handleChange}
              name={"pincode"}
              id={"pincode"}
              placeholder="demo@gmail.com"
            />
            <DropDownField
              options={[
                { key: 1, value: "India" },
                { key: 2, value: "Usa" },
                { key: 3, value: "Japan" },
              ]}
              label="Country"
              name={"country"}
              id={"country"}
              placeholder="country"
              error={""}
              value={formState.country}
            />
          </div>
          <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-52 m-auto my-6">
            SAVE
          </Button>
        </form>
      </Drawer>
    </>
  );
}

export default PersonalInfo;
