import { Fragment, useState, useRef } from 'react'
import { useLoaderData } from "react-router-dom";
import { Button, Card, Carousel, Col, Row } from "antd";
import { MagnifyingGlassIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Container, DropDownField } from '../../components/ui-components';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import InnerHeroSection from '../../components/shared-components/InnerHeroSection';
import { FaqAccountProfileManagement, FaqInstructors, FaqLearners, FaqPaymentsBilling, FaqQuestions } from '../../components/icons';

export async function faqLoader(){
  return {
    date: new Date().toISOString(),
  };
}

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  }
  // More questions...
]


const items = [
  {
    id: '01',
    title: "General Platform Questions",
    icon: <FaqQuestions color={'#00000090'} />,
  },
  {
    id: '02',
    title: "For Learners",
    icon: <FaqLearners color={'#00000090'} />,
  },
  {
    id: '03',
    title: "Payments and Billing",
    icon: <FaqPaymentsBilling color={'#00000090'} />,
  },
  {
    id: '04',
    title: "Account & Profile Manage",
    icon: <FaqAccountProfileManagement color={'#00000090'} />,
  },
  {
    id: '05',
    title: "For Instructors",
    icon: <FaqInstructors color={'#00000090'} />,
  },
  {
    id: '06',
    title: "Payments and Billing",
    icon: <FaqPaymentsBilling color={'#00000090'} />,
  },
  {
    id: '07',
    title: "For Learners",
    icon: <FaqLearners color={'#00000090'} />,
  },
];

export default function FaqPage() {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  let data = useLoaderData();


  // const handleBeforeChange = (from, to) => {
  //   setCurrentSlide(to);
  // };
  const onSlideChange = (iin) => {
    console.log('iin', iin);
  }

  const goToPrev = () => {
    carouselRef.current.prev();
    // console.log(currentSlide);
    setCurrentSlide(currentSlide-1);
    // console.log(currentSlide-1);
  };

  const goToNext = () => {
    carouselRef.current.next();
    // console.log(currentSlide);
    setCurrentSlide(currentSlide+1);
    // if((currentSlide+1) >= items.length){
    //   carouselRef.current.slickGoTo(0);
    // }

    // console.log(currentSlide+1);
  };


  return (
    <>
      {/* Hero Section */}
      <section className="bg-gray-100 py-20">
        <Container>
          <div className="text-center w-full max-w-lg m-auto">
            <h1 className="text-5xl font-bold text-text mb-4" style={{lineHeight:'60px'}}>{'Frequently Asked Questions'}</h1>
            <div className='relative w-full'>
              <input id={'faq-search'} type={'text'} placeholder='What would you like to learn?' className={'bg-white border-0 text-sm rounded-4xl w-full h-10 pr-8 text-input-text placeholder:text-input-placeholder'} />
              <MagnifyingGlassIcon
                className="pointer-events-none absolute right-2 top-2.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            {/* <DropDownField options={[{key:1, value:'Option1'},{key:2, value:'Option2'},{key:3, value:'Option3'}]} label="Email ID / Phone Number" name={"username"} id={"username"} placeholder="demo@gmail.com" error={''} /> */}
          </div>
        </Container>
      </section>
      <section className="py-10">
        <Container>
          <div className="relative w-full m-auto">
            <Carousel
              ref={carouselRef}
              // dots
              // arrows
              draggable
              // autoplay
              // autoplaySpeed={3000}
              slidesToShow={5}
              slidesToScroll={1}
              style={{height:'100%'}}
              id={'faqs-slider'}
            >
            {items.map((item, index) => (
                <div className='p-3'>
                  <div className='flex flex-col justify-center items-start gap-6 p-6 h-48 bg-white shadow-lg'>
                    {item.icon}
                    <h5>{item.title}</h5>
                  </div>
                </div>
              ))}
            </Carousel>
            <div className=''>
              {/* {currentSlide > 0 && ( */}
              <div className='absolute -left-10 top-0 w-10 h-full flex items-center'>
                  <Button type="primary" className='bg-primary' onClick={goToPrev} shape="circle" icon={<ChevronLeftIcon className='size-7 text-white' />} />
                </div>
              {/* )} */}
              {/* {currentSlide < items.length - 1 && ( */}
                <div className='absolute -right-12 top-0 w-10 h-full flex items-center'>
                  <Button type="primary" className='bg-primary' onClick={goToNext} shape="circle" icon={<ChevronRightIcon className='size-7 text-white' />} />
                </div>
              {/* )} */}
            </div>
          </div>
        </Container>
      </section>
      <section className="py-10">
      <Container>
        <div className="w-full flex flex-col">
          <h4 className='text-text text-xl font-medium mb-5'>General Platform Questions</h4>
          <div className="mt-10 flex flex-col gap-5">
            {faqs.map((faq, index) => (
              <div key={index} className='p-3 border-b-2 border-b-gray-5 rounded-none'>
                <Disclosure key={index} as="div" className="">
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-lg font-semibold">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="size-6 group-[&:not([data-open])]:hidden" />
                    </span>
                  </DisclosureButton>
                  <DisclosurePanel as="dd" className="mt-2 pr-12">
                    <p className="text-sm text-text">{faq.answer}</p>
                  </DisclosurePanel>
                </Disclosure>
              </div>
            
            ))}
          </div>
        </div>
        </Container>
      </section>
    </>
  )
}