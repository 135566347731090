import React from "react";

const Icon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 15H15C14.7167 15 14.4792 15.0958 14.2875 15.2875C14.0958 15.4792 14 15.7167 14 16C14 16.2833 14.0958 16.5208 14.2875 16.7125C14.4792 16.9042 14.7167 17 15 17H18C18.2833 17 18.5208 16.9042 18.7125 16.7125C18.9042 16.5208 19 16.2833 19 16V13C19 12.7167 18.9042 12.4792 18.7125 12.2875C18.5208 12.0958 18.2833 12 18 12C17.7167 12 17.4792 12.0958 17.2875 12.2875C17.0958 12.4792 17 12.7167 17 13V15ZM7 9H9C9.28333 9 9.52083 8.90417 9.7125 8.7125C9.90417 8.52083 10 8.28333 10 8C10 7.71667 9.90417 7.47917 9.7125 7.2875C9.52083 7.09583 9.28333 7 9 7H6C5.71667 7 5.47917 7.09583 5.2875 7.2875C5.09583 7.47917 5 7.71667 5 8V11C5 11.2833 5.09583 11.5208 5.2875 11.7125C5.47917 11.9042 5.71667 12 6 12C6.28333 12 6.52083 11.9042 6.7125 11.7125C6.90417 11.5208 7 11.2833 7 11V9ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 18H20V6H4V18Z" fill="#5F6368"/>
    </svg>


  );
};

export default Icon;
