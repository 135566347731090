import React from "react";

const CourseTitle = () => {
  return (
    <section className="p-6 bg-white shadow">
      <h2 className="text-2xl font-semibold">How to Become a UX Designer</h2>
      <div className="flex justify-between items-center mt-4">
        <p className="text-xl font-bold">$49.99</p>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">Enroll</button>
      </div>
    </section>
  );
};

export default CourseTitle;
