import React, { useState } from "react";
import { GoogleIcon } from "../icons";
import { Button } from "../ui-components";

// type Props = {
//     action:any,
//     loading:string,
// };

const GoogleButton= () => {
  const [focus, setFocus] = useState(false);
//   const { action, loading } = props;
  const googleLoginAction = () => {
    console.log('Google Login');
  }


  return (
    <Button onClick={googleLoginAction} className="google-button" type='button' icon={<GoogleIcon />} >Google</Button>
  );
};

export default GoogleButton;
