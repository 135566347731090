import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";
import GoogleButton from '../../components/shared-components/GoogleButton';
import AppleButton from '../../components/shared-components/AppleButton';
import AuthFooter from '../../components/shared-components/AuthFooter';
import { Button, Divider } from '../../components/ui-components';
import { XMarkIcon } from '@heroicons/react/24/outline';

export async function authLoader() {
  // await sleep();
  return {
    date: new Date().toISOString(),
  };
}


const Auth = () => {
  const navigate = useNavigate();
  let data = useLoaderData();

  return (
    <div className='flex flex-col justify-start items-center gap-4'>
      <img className='w-[160px] text-center' src={require('../../assets/images/logo.png')} />
      <h3 className='text-2xl text-text font-medium'>{'Shape Your Tomorrow with Simplitrain'}</h3>
      <div className='flex flex-row items-center justify-between w-full gap-2'>
          <GoogleButton />
          <AppleButton />
      </div>
      <Divider>or</Divider>

        {/* background-color: var('--primary-color');
        color: var('--white-color');
        width: 100%;
        padding: 20px;
        font-family: var('--button-text');
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: center; */}

      <Button href='/auth/signin' type='link' color='primary' variant='solid' className={'w-full'} rounded={false}>Continue with Email / Phone</Button>
    </div>
  );
}

export default Auth;