import React from "react";
import EducationCard from "./EducationCard";
// import { EditPencilTwo } from "../../../components/icons";
import { Drawer, FloatingTextField } from "../../../components/ui-components";
import { Dialog, DialogPanel, DialogTitle, Field } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/ui-components";
import { useState } from "react";
import { DropDownField } from "../../../components/ui-components";

function Education() {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    degree: "",
    college: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = (e) => {
    e.preventDefault();
    console.log(formState);
  };
  return (
    <>
      <div className="relative">
        <button
          className="border bg-[#292D32] text-white text-base border-gray-300 px-4 py-2 rounded-full absolute right-6 top-0 w-22 transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          ADD
        </button>
        <h3 className="mb-8 font-bold text-lg">Education</h3>

        <div className="bg-white px-6 py-6 rounded-xl">
          <EducationCard />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Add Education">
          <form
            onSubmit={onFinish}
            method="POST"
            className="flex flex-col justify-between h-full w-96 m-auto mt-6">
            {/* <FloatingLabel variant="filled" className='h-16 mb-1 pl-4 pr-4 w-full bg-input-background border-0 text-input-text placeholder-transparent rounded-xl' label="Email ID / Phone Number" /> */}
            <div className="flex flex-col justify-center">
            <DropDownField
              options={[
                { key: 1, value: "Bachlor" },
                { key: 2, value: "Masters" },
                { key: 3, value: "PHD" },
              ]}
              label="DEGREE"
              name={"degree"}
              id={"degree"}
              placeholder="degree"
              error={""}
              value={formState.degree}
            />
            <FloatingTextField
              label="COLLEGE"
              type="text"
              value={formState.college}
              onChange={handleChange}
              name={"college"}
              id={"college"}
              // placeholder="demo@gmail.com"
            />
            <FloatingTextField
              label="FIELD OF STUDY"
              type="text"
              value={formState.fieldOfStudy}
              onChange={handleChange}
              name={"fieldOfStudy"}
              id={"fieldOfStudy"}
              // placeholder="demo@gmail.com"
            />
            <FloatingTextField
              label="START DATE"
              type="date"
              value={formState.startDate}
              onChange={handleChange}
              name={"startDate"}
              id={"startDate"}
            />
            <FloatingTextField
              label="END DATE"
              type="date"
              value={formState.endDate}
              onChange={handleChange}
              name={"endDate"}
              id={"endDate"}
            />
            </div>
            
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-52 m-auto my-6">
              ADD
            </Button>
          </form>
      </Drawer>

    </>
  );
}

export default Education;
